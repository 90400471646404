import React from 'react';
import styled from "styled-components";
import productBg from 'src/assets/images/dev/productBg.png';
import Flex from "../../components/Box/Flex";
import arrow_right from "../../assets/svg/arrow_right.svg";
import Grid from "../../components/Box/Grid";
import { FadeUp } from "../../styles/aos";

const ProductStyle = styled.div`
    position: relative;
    height: 754px;
    background: url(${productBg});
    background-size: 100% 100%;
    margin-top: 150px;
`;

const Title = styled.h3`
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #3D3D3D;
    margin: 0 0 120px 0;
`;

const Tag = styled.div`
    position: relative;
    font-size: 30px;
    font-weight: 600;
    line-height: 26px;
    margin-right: 20px;
    z-index: 1;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: -7px;
        width: 100%;
        height: 4px;
        background: #60FE83;
        transform: translateY(-200%);
        z-index: -1;
    }
`;

const ListBox = styled(Grid)`
    grid-template-rows: repeat(4, auto);
    grid-auto-flow: column;
    width: max-content;
    margin: auto;
    grid-column-gap: 480px;
    grid-row-gap: 10px;
    color: #fff;
`;

const ListItem = styled.div`
    width: 378px;
`;
const ListItemTitle = styled.h3`
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
`;

export default function Product() {

  const products = [
    {
      title: "POW algorithm",
      text: "The current mainstream currency adopts conventional algorithms that utilize specific algorithms, proprietary mining machines, and computing chips to calculate hash values to obtain computing power.",
    },
    {
      title: "DPOW algorithm",
      text: "Intended to solve the high power consumption problem of the POW algorithm, reducing energy consumption and improving TPS efficiency through node blocking."
    },
    {
      title: "Combination of POW+DPOS algorithm",
      text: "A very excellent combination of two algorithms, with wider practicality, can significantly reduce the number of nodes involved in verification and accounting, and can achieve consensus verification at the second level."
    },
    {
      title: "POC storage mechanism",
      text: "The most popular IPFS mechanism currently relies on hard disk storage space as the algorithm basis."
    },
    {
      title: "Hot and cold wallets",
      text: "Secure storage of private keys for cold money, suitable for hot wallet networking, safe and reliable."
    },
    {
      title: "Traceability",
      text: "Once the data on the public chain is linked, it cannot be changed and has a strong traceability effect, quickly identifying problematic products."
    },
    {
      title: "DAPP",
      text: "Decentralized blockchain distributed applications"
    },
    {
      title: "Blockchain Browser",
      text: "Browse and query blockchain information"
    }
  ]

  return (
    <ProductStyle {...FadeUp}>
      <Title>Product characteristics</Title>
      <ListBox>
        {
          products.map((item, index) => {
            return <ListItem key={index}>
              <Flex alignItems={"center"}>
                <Tag>0{index + 1}</Tag>
                <ListItemTitle>{item.title}</ListItemTitle>
              </Flex>
              <div style={{width: "300px", marginLeft: "52px"}}>
                {item.text}
              </div>
            </ListItem>
          })
        }
      </ListBox>
    </ProductStyle>
  )
}
