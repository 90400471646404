import Box from "../../components/Box";
import React, { useState } from "react";
import {
  ArrowBox,
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle, ColorText,
  IntroBox,
  IntroContent,
  IntroGroup, IntroGroupContent, IntroGroupTitle, IntroIconBox,
  IntroTitle, SamplesBox, SamplesItem, SamplesItemContent, SamplesItemHeader, SamplesItemTitle, SamplesTitle
} from "./style";
import group1 from 'src/assets/images/nft/group1.svg';
import group2 from 'src/assets/images/nft/group2.svg';
import group3 from 'src/assets/images/nft/group3.svg';
import group4 from 'src/assets/images/nft/group4.svg';

import samples1 from 'src/assets/images/nft/samples1.png';
import samples2 from 'src/assets/images/nft/samples2.png';
import samples3 from 'src/assets/images/nft/samples3.png';
import samples4 from 'src/assets/images/nft/samples4.png';
import SvgIcon from "../../components/svgIocn/SvgIcon";
import { dropdownIcon } from "../../assets/svgManage";
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";


export default function NFTTradingSystem() {
  const [active, setActive] = useState<number | undefined>();
  const nav = useNavigate()

  const introArr=  [
    {
      icon: group1,
      title: "Development of NFT blind box system",
      desc: "The NFT blind box mechanism can motivate planners, creators, and buyers, increase liquidity, give planners opportunities, and earn their own NFT transaction commissions from it"
    },
    {
      icon: group2,
      title: "NFT Artwork Trading System",
      desc: "NFT has the characteristics of irreplaceability, indivisibility, and immutability, which enable it to be bound to specific things and provide a method for marking the ownership of digital assets"
    },
    {
      icon: group3,
      title: "NFT Game Development",
      desc: "The combination of sandbox game theory and community selection brings innovative utility, community building, and epic adventure to all players. You can participate in creating storytelling, fighting with other bloodlines to improve, and more."
    },
    {
      icon: group4,
      title: "Development of NFT trading platform",
      desc: "The authenticity of the 'digital certificate' will never be damaged or lost. Its scope of application depends on people's imagination. In addition to artistic works in the usual sense such as images and music,"
    }
  ];

  const SamplesArr = [
    {
      title: <SamplesItemTitle>
          <span>Development of NFT</span>
          <ColorText>trading platform</ColorText>
        </SamplesItemTitle>,
      desc: "Art and gaming are highly regarded industries in the NFT market, driving the development of NFT tokenization. Sell and exchange various digital projects, such as digital products, art, sculpture traders, and creative projects",
      image: samples1
    },
    {
      title: <SamplesItemTitle>
        <span>Development of NFT</span>
        <ColorText>blind box</ColorText>
        <span>system</span>
      </SamplesItemTitle>,
      desc: `NFT Open Protocol (ERC-721). Extracting and recording information about "digital content" itself and casting information. Content encoding. Content description of NFT casting time, NFT caster, NFT bit holder, etc`,
      image: samples2
    },
    {
      title: <SamplesItemTitle>
        <span>NFT</span>
        <ColorText>Game</ColorText>
        <span>Development</span>
      </SamplesItemTitle>,
      desc: "The virtual assets in the game provide unique gameplay, including rare assets such as characters, weapons, and upgrade tickets. The listed NFT assets have been approved by game developers for use in the game.",
      image: samples3
    },
    {
      title: <SamplesItemTitle>
        <span>NFT </span>
        <ColorText>Artwork</ColorText>
        <span>Trading System</span>
      </SamplesItemTitle>,
      desc: "NFT has the characteristics of irreplaceability, indivisibility, and non compilation, which can be bound to specific artifacts and provide a method for marking the ownership of digital assets",
      image: samples4
    }
  ];



  return <Box background={`#fff`} pt={"168px"}>
    <BannerContent {...FadeUp}>
      <BannerTitle>NFT trading system</BannerTitle>
      <BannerSubtitle>Development of nft trading platform. NFT Art auction system. NFT game development</BannerSubtitle>
      <BannerButton onClick={() => nav("/quotation")}>TRIAL</BannerButton>
    </BannerContent>
    <IntroBox {...FadeUp}>
      <IntroTitle>Product Function Introduction</IntroTitle>
      <IntroContent>
        {
          introArr.map((item, index) => {
            return  <IntroGroup key={index}>
              <IntroIconBox>
                <img src={item.icon} alt=""/>
              </IntroIconBox>
              <IntroGroupTitle>{item.title}</IntroGroupTitle>
              <IntroGroupContent>{item.desc}</IntroGroupContent>
            </IntroGroup>
          })
        }
      </IntroContent>
    </IntroBox>
    <SamplesBox {...FadeUp}>
      <SamplesTitle>Product samples</SamplesTitle>
      {
        SamplesArr.map((item, index) => {
          return <SamplesItem key={index}
                              active={active === index}
                              onClick={() => {
                                setActive(active === index ? undefined : index)
                              }}>
            <SamplesItemHeader>
              {item.title}
              <ArrowBox active={active === index}>
                <SvgIcon color={active === index ? "#23C84F" : "#979797"} dangerouslySetInnerHTML={dropdownIcon} />
              </ArrowBox>
            </SamplesItemHeader>
            {
              active === index
                ? <div>
                    <SamplesItemContent>{item.desc}</SamplesItemContent>
                    <img src={item.image} style={{height: "316px"}} alt=""/>
                  </div>
                : null
            }
          </SamplesItem>
        })
      }
    </SamplesBox>
  </Box>
}
