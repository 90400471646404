import styled from "styled-components";
import Logo from "../Logo";
import Flex from "../Box/Flex";
import {CONTENT_WIDTH} from "../../styles";
import {Link, useNavigate} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import {usePopper} from "react-popper";
import "antd/dist/antd.css"
import {Popover} from "antd";
import Box from "../Box";
import Column from "../Column";
import {MEDIA_WIDTHS} from "../../theme";
import NavbarMd from "./Navbar.md";

export const NavBarHeight = `96px`;

const Wrapper = styled.div<{bgColor?: string}>`
  background-color: ${({bgColor}) => bgColor || "transparent"};
  height: ${NavBarHeight};
  padding: 0 32px;
  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
    justify-content: center;
  }
`

const NavWrapper = styled.div<{color?: string}>`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  max-width: 1180px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  color: ${({color}) => color || "#fff"};
`

const NavbarText = styled.div<{
  isSelected?: boolean
  color?: string
}>`
  font-size: 20px;
  font-weight:  ${({isSelected}) => isSelected ? 900 : 400};;
  cursor: pointer;
  vertical-align: middle;
    //height: ${NavBarHeight};
  line-height: ${NavBarHeight};
  position: relative;
  transition: all .2s;

  &:hover {
    // color: #858585;
  }

  &::after {
    content: "";
    // display: ${({isSelected}) => isSelected ? `block` : `none`};
    display: none;
    background: #6F57FF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 1;
  }
`

const NavbarItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const NavbarExpandIcon = styled.img`
  object-fit: contain;
  width: 16px;
  height: 14px;
  position: absolute;
  right: 32px;
  top: calc(64px / 2 - 14px / 2);
  cursor: pointer;
  display: none;

  ${({theme}) => theme.mediaQueries.md} {
    display: block;
  }
`

const LogoBox = styled.a`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: inherit;
`;

interface INavbarText {
  title: string,
  subTitle?: string[],
  to?: string
}

const Navbar = () => {
  const navbarTextData: INavbarText[] = [
    {
      title: "Home",
      to: "/"
    },
    {
      title: "Public chain development",
      to: "/development"
    },
    {
      title: "Product Services",
      to: "/product/cex"
    },
    {
      title: "Product Services",
      to: "/product/cex"
    },
    {
      title: "Quotation",
      to: "/quotation"
    }
  ]

  const nav = useNavigate()

  const [sidebarHidden, setSidebarHidden] = useState(true)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | undefined | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | undefined | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | undefined | null>(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [{name: 'arrow', options: {element: arrowElement}}],
  });

  const toggleSidebars = () => setSidebarHidden(!sidebarHidden)

  return <Wrapper>
    <NavWrapper color={(window.location.pathname.startsWith("/product") || window.location.pathname.startsWith("/about")) ? "#161729" : "#fff"}>
      <Flex alignSelf={"center"}>
        <LogoBox href="/">
          <Logo height={`46px`}/>
          <span style={{marginLeft: "10px"}}>Innoweb3</span>
        </LogoBox>
      </Flex>
      <NavbarItemWrapper>
        <NavbarText
          isSelected={window.location.pathname === "/"} onClick={() => nav("/")}>Home</NavbarText>
        <NavbarText
          isSelected={window.location.pathname === "/development"} onClick={() => nav("/development")}>Public chain development</NavbarText>
        <Popover
          content={<NavbarSubTitle/>}
          overlayClassName={``}>
          <NavbarText isSelected={window.location.pathname.startsWith("/product")}>Product Services</NavbarText>
        </Popover>
        <NavbarText
          isSelected={window.location.pathname === "/quotation"} onClick={() => nav("/quotation")}>Quotation</NavbarText>
        <NavbarText
          isSelected={window.location.pathname === "/about"} onClick={() => nav("/about")}>About us</NavbarText>
        {/*<NavbarText*/}
        {/*  isSelected={window.location.pathname === "/pricing"}*/}
        {/*  onClick={() => nav("/pricing")}>*/}
        {/*  PRICING*/}
        {/*</NavbarText>*/}
      </NavbarItemWrapper>
    </NavWrapper>
    <NavbarExpandIcon onClick={toggleSidebars} src={require('../../assets/svg/navbar/navbar_expand.svg').default}/>
    <NavbarMd _display={!sidebarHidden} dismiss={() => setSidebarHidden(true)}/>
  </Wrapper>
}


export default Navbar

const NavbarSubItem = styled.div<{
  isSelected?: boolean
}>`
  color: ${({isSelected}) => isSelected ? `#6C55FA` : `#24292f80`};
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    color: #6C55FA;
  }
`

export const navbarSubData = [
  {
    title: "DXC exchange",
    link: "/cex",
    href: "https://dxc.xsdx.xyz/",
  },
  {
    title: "DXQ exchange",
    link: "/dex",
    href: "https://dxq.xsdx.xyz/"
  },
  {
    title: "Decentralized lending",
    link: "/decentralized"
  },
  {
    title: "Cross-chain bridge",
    link: "/cross-chain"
  },
  {
    title: "Decentralized transaction mining",
    link: "/transaction-mining"
  },
  {
    title: "NFT trading system",
    link: "/nft-trading"
  },
  {
    title: "Blockchain wallet",
    link: "/blockchain-wallet"
  },
  {
    title: "Payment system",
    link: "/payment"
  }
]

const NavbarSubTitle = () => {
  const nav = useNavigate()

  return <Column padding={`0px 6px`} borderRadius={`12px`} background={`#fff`}>
    {
      navbarSubData.map((it) => {
        return <NavbarSubItem
          isSelected={window.location.pathname === `/product${it.link}`}
          onClick={() => {
            nav(`/product${it.link}`)
          }}
          key={it.title}
        >{it.title}</NavbarSubItem>
      })
    }
  </Column>
}
