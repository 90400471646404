import styled from "styled-components";
import banner from 'src/assets/images/cross-chain/banner.png';
import Flex from "../../components/Box/Flex";
import Box from "../../components/Box";
import { BOX_CONTENT_WIDTH } from "../../styles";
import bg2 from 'src/assets/images/cross-chain/bg2.png';

export const CrossChainStyle = styled.div`
    padding-top: 96px;
`;

export const Banner = styled.div`
    height: 502px;
    position: relative;
    background: url(${banner});
    background-size: cover;
    margin-bottom: 206px;
`;
export const BannerContent = styled.div`
    position: absolute;
    left: calc((100vw - 1082px) / 2);
    top: 51px;
    width: 551px;
    height: 569px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
    padding: 82px 36px 0;
    box-sizing: border-box;
`;
export const BannerTitle = styled.h3`
    font-size: 48px;
    font-weight: 900;
    color: #00032E;
    margin: 0;
    &:not(:last-child){
        margin-right: 8px;
    }
    &:last-child{
        color: #27E85A;
    }
`;
export const BannerDesc = styled.div`
    width: 422px;
    font-size: 22px;
    font-weight: 500;
    color: #3D3D3D;
    margin-top: 22px;
`;
export const BannerButton = styled.button`
    width: 144px;
    height: 58px;
    border-radius: 2px;
    opacity: 1;
    background: #15A83C;
    border: none;
    outline: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    margin-top: 76px;
    cursor: pointer;
`;

export const IntroductionBox = styled(Box)`
    width: 1082px;
    margin: 92px auto;
`;
export const IntroBox = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 22px;
`;
export const IntroGroup = styled(Flex)`
    justify-content: space-between;
    padding: 0 0 0 32px;
    &:first-child{
        padding: 0;
    }
    &:last-child{
        
    }
`;
export const IntroContent = styled.div`
    
`;
export const IntroTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #3D3D3D;
    margin-bottom: 20px;
`;
export const IntroDesc = styled.div`
    font-size: 14px;
    color: #3D3D3D;
    margin-right: 16px;
`;

export const SplitBox = styled.div`
    height: 188px;
    background: url(${bg2});
    background-size: cover;
    margin: 30px 0;
`;
