import styled, { css } from "styled-components";
import bannerBg from 'src/assets/images/payment/bannerBg.png';
import bgIcon1 from "../../assets/images/trading/bgIcon1.png";
import decorate2 from "../../assets/images/decorate2.png";
import decorate3 from "../../assets/images/decorate3.png";
import { BOX_CONTENT_WIDTH } from "../../styles";
import Box from "../../components/Box";
import Flex from "../../components/Box/Flex";
import decorateImg2 from "../../assets/images/decorate.png";
import SolutionsBg from 'src/assets/images/payment/SolutionsBg.png';
import { NavBarHeight } from "../../components/Navbar";

const contentWidth = "888px";
export const BannerContent = styled.div`
    position: relative;
    height: 502px;
    margin: ${NavBarHeight} auto 0;
    color: #fff;
    text-align: center;
    background: url(${bannerBg});
    background-size: cover;
`;
export const BannerTitle = styled.h3`
    font-size: 50px;
    font-weight: 900;
    line-height: 80px;
    margin: 0 0 10px;
    color: #00032E;
    padding-top: 90px;
`;
export const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 500;
    color: #00032E;
    padding-left: 28px;
`;
export const BannerButton = styled.button`
    width: 144px;
    height: 58px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 500;
    color: #191933;
    margin-top: 56px;
    margin-left: 28px;
    cursor: pointer;
`;


export const ServiceBox = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    margin: -104px auto 112px;
    height: 410px;
    border-radius: 26px;
    background: url(${decorate2}) -34px -51px / 212px 212px no-repeat,
                url(${decorate2}) 1130px 16px / 21px 21px no-repeat,
                url(${decorate2}) 768px 314px / 74px 74px no-repeat,
                linear-gradient(to right, #fff, #fff);
    box-shadow: 0px 4px 10px 0px rgba(155, 155, 155, 0.3);
`;
export const ServiceContent = styled(Box)`
    padding: 0 52px 0 60px;
    height: 100%;
    display: flex;
    align-items: center;
`;
export const ServiceTitle = styled.h3`
    width: 248px;
    font-size: 40px;
    font-weight: 900;
    line-height: 46px;
    color: #3D3D3D;
    position: relative;
    margin-right: 52px;
    padding-top: 24px;
    margin-top: 24px;
    &:after{
        content: "";
        width: 57px;
        height: 9px;
        opacity: 1;
        background: #3CCE63;
        position: absolute;
        left: 0;
        bottom: -28px;
    }
`;
export const ServiceMenu = styled.div`
    display: grid;
    grid-template-columns: 356px 1fr;
    grid-gap: 40px 60px;
    margin-left: 56px;
`;
export const IconBox = styled(Flex)`
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-right: 16px;
    background: #DAF4E2;
    flex-shrink: 0;
    border-radius: 50%;
`;
export const ServiceMenuItem = styled(Flex)`
    
`;
export const ServiceItemTitle = styled.h5`
    font-size: 18px;
    font-weight: 700;
    color: #3D3D3D;
    margin: 0 0 12px;
`;
export const ServiceItemDesc = styled.div`
    font-size: 16px;
`;

export const IntroductionBox = styled(Box)`
    width: ${BOX_CONTENT_WIDTH};
    margin: 92px auto 0;
`;
export const Title = styled.div`
    position: relative;
    font-size: 40px;
    font-weight: 900;
    color: #3D3D3D;
    margin-bottom: 80px;
    padding-left: 58px;
`;
export const DecorateIcon2 = styled.div`
    width: 153px;
    height: 153px;
    background: url(${decorateImg2});
    background-size: 100% 100%;
    position: absolute;
`;

export const IntroBox = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 78px;
    padding-left: 36px;
`;
export const IntroItem = styled.div`
    
`;
export const IntroItemTitle = styled.h3`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    margin:  0 0 8px;
`;

export const IntroItemContent = styled.div`
    width: 224px;
    padding-left: 22px;
    font-size: 14px;
    box-sizing: border-box;
`;

export const SolutionsBox = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 0;
    padding-bottom: 140px;
    padding-top: 190px;
    background: url(${SolutionsBg}) 852px 0 / 298px 308px no-repeat;
`;
export const SolutionsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 45px;
    padding-left: 60px;
`;
export const SolutionsGroup = styled.div<{ active?: boolean }>`
    min-height: 302px;
    padding: 28px 36px;
    background: #F0F0F0;
    border-radius: 18px;
    opacity: 0.4;
    ${({active}) => active 
            ? css`
                background: #D6FFE1;
                opacity: 1;
                ` 
            : null }
`;
export const SolutionsGroupTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin: 18px 0;
`;
export const SolutionsGroupDesc = styled.div`
    font-size: 14px;
    font-weight: 400;
`;

export const PaginationBox = styled(Flex)`
    position: absolute;
    left: calc(50% + 30px);
    bottom: 98px;
    transform: translateX(-50%);
`;
export const PaginationItem = styled(Box)<{active?: boolean}>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #020714;
    cursor: pointer;
    &:not(:last-child){
        margin-right: 10px;
    }
    ${({active}) => active
            ? css`
                background: #020714;
                ` 
            : null}
`;
