import { CSSProperties, ReactNode } from "react";
import Flex from "../Box/Flex";
import styled from "styled-components";
import Column from "../Column";
import {CONTENT_WIDTH} from "../../styles";
import Box from "../Box";
import {MEDIA_WIDTHS} from "../../theme";
import {FadeUp} from "../../styles/aos";

export const SectionTitle = styled.div`
  color: #020714;
  font-size: 60px;
  font-weight: 700;
  text-align: center;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 32px;
  }
`

export const SectionSubtitle = styled.div`
  color: #020714;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin-top: 32px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 24px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0 16px;
  }
`

const ContentBox = styled(Box)`
  margin-top: 72px;
  margin-bottom: 80px;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 32px;
    margin-bottom: 40px;
  }
`

const WrapperContent = styled(Column)`
  margin-top: 80px;
  position: relative;  
  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 40px;
  }
`

const Section = (props: {
  title: string | ReactNode,
  subtitle?: string | ReactNode,
  children?: ReactNode,
  isDark?: boolean
  contentStyle?: CSSProperties
}) => {
  return <Wrapper>
    <WrapperContent maxWidth={`${MEDIA_WIDTHS.xl}px`} width={`100%`}  style={props.contentStyle}>
      <SectionTitle {...FadeUp}>{props.title}</SectionTitle>
      {props.subtitle && <SectionSubtitle {...FadeUp}>{props.subtitle}</SectionSubtitle>}
      <ContentBox marginTop={`72px`} marginBottom={`80px`}>{props.children}</ContentBox>
    </WrapperContent>
  </Wrapper>
}

export default Section
