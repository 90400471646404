import React from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import dataBg from 'src/assets/images/dataBg.png';
import Flex from "../../components/Box/Flex";

const BusinessDataStyle = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    height: 440px;
    margin: 0 auto;
    background: url(${dataBg});
    background-size: 100% 100%;
    overflow: hidden;
`;

const BusinessTitle = styled.div`
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    font-size: 30px;
    font-weight: 700;
    &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-200%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #050505;
    }
    &:after{
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(200%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #050505;
    }
`;

const DataBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 68px 48px 0;
`;

const DataGroup = styled.div`
    
`;

const DataVal = styled.div`
    font-size: 70px;
    font-weight: 700;
    //line-height: 104px;
    color: #3D3D3D;
`;

const DataLabel = styled.div`
    font-size: 22px;
    font-weight: 400;
    color: #979797;
`;


export default function BusinessData() {

    return (
        <BusinessDataStyle>
          <DataBox>
            <DataGroup>
              <DataVal>50</DataVal>
              <DataLabel>We have</DataLabel>
              <DataLabel> technical experts</DataLabel>
            </DataGroup>
            <DataGroup>
              <DataVal>120</DataVal>
              <DataLabel>Cumulative</DataLabel>
              <DataLabel>customers served</DataLabel>
            </DataGroup>
            <DataGroup>
              <DataVal>300</DataVal>
              <DataLabel>Cumulative</DataLabel>
              <DataLabel>projects</DataLabel>
            </DataGroup>
            <DataGroup>
              <DataVal>
                <span>$1.5</span>
                <span style={{fontSize: "30px", lineHeight: "auto"}}>B</span>
              </DataVal>
              <DataLabel>Cumulative</DataLabel>
              <DataLabel>TVL of over</DataLabel>
            </DataGroup>
          </DataBox>
          <BusinessTitle>Business Data</BusinessTitle>
        </BusinessDataStyle>
    )
}
