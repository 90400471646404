import React from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import TechnicalBg from 'src/assets/images/Technical.png';

const TechnicalStyle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 28px auto 0;
    height: 840px;
    background: url(${TechnicalBg});
    background-size: 100% 100%;
`;

export default function Technical() {

  return (
    <TechnicalStyle>

    </TechnicalStyle>
  )
}
