import React, { CSSProperties } from 'react';
import styled from "styled-components";

const DecorateIconStyle = styled.div`
    position: absolute;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(55, 253, 107, 0.60) 0%, rgba(148, 250, 175, 0.00) 100%);
`;

type IProps = {
  style?: CSSProperties
}
export default function DecorateIcon(props: IProps) {

  return (
    <DecorateIconStyle style={props.style}>

    </DecorateIconStyle>
  )
}
