import {ReactNode} from "react";

export interface ICoreBusinessData {
  title: string,
  largeIcon: string,
  desc: string,
  navTo: string,
  href?: string
  bottomText: string[]
}

export const CoreBusinessData: ICoreBusinessData[] = [
  {
    title: `DXC Exchange`,
    largeIcon: require("../../assets/svg/business/business_1.svg").default,
    desc: `The digital asset trading system has a powerful memory matching engine to ensure high-concurrency transaction support, including a complete front-end and back-end system, which can ensure the privacy security, system security and asset security of both the platform and users. Support strategy delegation and internationalization.`,
    navTo: "/product/cex",
    href: "https://dxc.xsdx.xyz/",
    bottomText: [
      "Spot trading &nbsp;&nbsp;&nbsp; Leveraged trading",
      "Contract trading &nbsp; OTC system",
      "trackingsystem"]
  },
  {
    title: `DXQ Exchange`,
    href: "https://dxq.xsdx.xyz/",
    largeIcon: require("../../assets/svg/business/business_2.svg").default,
    desc: `DXQ exchange is a fully transparent decentralized perpetual contract trading platform. The platform uses the messages under the encrypted signature chain to establish an effective order book, providing users with extremely high liquidity and extremely low transaction slippage.`,
    navTo: "/product/dex",
    bottomText: ["Currency trading",
      "Contract transactions",
      "Lending &nbsp; Leveraged trading"]
  }, {

    title: `Decentralized Lending`,
    largeIcon: require('../../assets/svg/business/business_3.svg').default,
    desc: `Decentralization a new decentralized over-lending protocol. Support borrowing and support users to improve capital utilization through excessive borrowing, and amplify the principal to participate in liquidity mining to obtain more income.`,
    navTo: "/product/decentralized",
    bottomText: ["Synthetic assets",
      "Non destructive pledge mining",
      "Liquidity mining &nbsp;&nbsp;&nbsp; Loan Clearing"]
  }, {
    title: `Cross-chain Bridge`,
    largeIcon: require('../../assets/svg/business/business_4.svg').default,
    desc: `A cross-chain bridge provides a compatible channel between two public chains with different protocols and rules, so that one chain can obtain transactions on the other chain through an oracle. The essence of a cross-chain bridge is to circulate the same asset on two chains. When cross-chain occurs, new assets are minted on one chain, and the same token is destroyed from the other chain to achieve cross-chain balance.`,
    navTo: "/product/cross-chain",
    bottomText: ["Multi Chain Tokens",
      "Fast and convenient",
      "Low transfer fees"]
  }, {

    title: `Decentralized trading and mining`,
    largeIcon: require('../../assets/svg/business/business_5.svg').default,
    desc: `The integrated DeFi platform provides one-stop liquidity services for more high-quality assets, and provides users with a more secure, credible, diverse and cost-effective trading experience. Cryptocurrency trading, faster and cheaper.`,
    navTo: "/product/transaction-mining",
    bottomText: ["Stablecoin mining",
      "Single currency mining",
      "LP mining &nbsp; Machine gun poolevenue",
      "Computational mining"]
  }, {

    title: `NFT Trading System`,
    largeIcon: require('../../assets/svg/business/business_6.svg').default,
    desc: `A marketplace featuring various forms of digital artwork and collectibles. Backed by the blockchain infrastructure and community, the NFT marketplace provides a highly liquid centralized trading platform for users to launch and trade NFTs.`,
    navTo: "/product/nft-trading",
    bottomText: ["Casting NFT",
      "NFT transactions",
      "NFT blind box &nbsp;&nbsp;&nbsp; NFT excavation"]
  }, {

    title: `Blockchain Wallet`,
    largeIcon: require('../../assets/svg/business/business_7.svg').default,
    desc: `It supports cross-exchange transactions, provides more convenient on-chain asset trading services, and supports multi-chain one-stop asset management. Through professional security systems, risk control systems, hot and cold wallet systems, financial monitoring systems, etc., the security of digital assets is guaranteed in multiple dimensions.`,
    navTo: "/product/blockchain-wallet",
    bottomText: ["HD wallet (single product)",
      "Cloud based SaaS wallet",
      "Full function wallet (combined solution)",
      "Asset Management and Financial ..."
    ]
  }, {

    title: `Payment System`,
    largeIcon: require('../../assets/svg/business/business_8.svg').default,
    desc: `A transaction system where digital assets and legal assets can be directly traded and paid. Provide a complete set of transaction systems including professional K-line, depth map, memory matching system, background management system automatic account confirmation assistant, and support WeChat, Alipay and bank card payments. Only available in countries and regions where digital transactions are legal and compliant.`,
    navTo: "/product/payment",
    bottomText: ["Digital asset payment docking",
      "Online transactions",
      "Collection and payment",
      "Offline QR code payment payment"]
  }
]
