import styled, {CSSObject, FlattenSimpleInterpolation} from "styled-components";
import {
  background,
  BackgroundProps, border,
  BorderProps, color, ColorProps, flexbox,
  FlexboxProps, grid,
  GridProps, layout,
  LayoutProps, position,
  PositionProps, shadow, ShadowProps, space,
  SpaceProps, StylesProps, typography, TypographyProps
} from "styled-system";
import React, {HTMLAttributes} from "react";

export interface BaseProps extends React.RefAttributes<any> {
  as?: React.ElementType | undefined,
  css?: CSSObject | FlattenSimpleInterpolation | string | undefined
}

export interface BoxKnownProps extends BaseProps,
  BorderProps,
  PositionProps,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  BackgroundProps,
  ShadowProps {
}

export interface BoxProps extends BoxKnownProps,
  Omit<React.HTMLProps<HTMLDivElement>, keyof BoxKnownProps> {
}

const Box = styled.div<BoxProps>`
  ${border};
  ${position};
  ${space};
  ${layout};
  ${typography};
  ${color};
  ${flexbox};
  ${grid};
  ${background};
  ${shadow};
`

export default Box
