import React from "react";
import styled, { css } from "styled-components";

export const SvgIconStyle = styled.span<{color?: string, strokeColor?: string, height?: string, width?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width: ${({width}) => width || "unset"};
        height: ${({height}) => height || "unset"};
        path{
            fill: ${({color}) => color};
            ${({strokeColor}) => strokeColor 
                    ? css`
                        stroke: ${strokeColor};
                      ` 
                    : null}
        }
    }
`;
