import React from "react";
import Box from "../../components/Box";
import {
  AdvantageBox,
  AdvantageContent, AdvantageItem, AdvantageItemDesc, AdvantageItemTitle, AdvantageTitle,
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle, CaseBox, CaseContainer, CaseDesc, CaseRightBox, CaseTitle,
  HotBox, OptionItem
} from "./style";
import Flex from "../../components/Box/Flex";
import hotIcon from 'src/assets/images/block/hotIcon.png';
import group1 from 'src/assets/images/block/group1.png';
import group2 from 'src/assets/images/block/group2.png';
import group3 from 'src/assets/images/block/group3.png';

import case1 from 'src/assets/images/block/case1.png';
import case2 from 'src/assets/images/block/case2.png';
import case3 from 'src/assets/images/block/case3.png';
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";


export default function BlockchainWalletSystem() {
  const nav = useNavigate()
  const advantages = [
    {
      icon: group1,
      title: "ONE STOP SERVICE",
      desc: "Cloud Wallet&HD Wallet"
    },
    {
      icon: group2,
      title: "Multi public chain support",
      desc: "Multiple mainstream public chains"
    },
    {
      icon: group3,
      title: "Multiple validations",
      desc: "Multiple signatures distributed around the world to protect private keys"
    }
  ];



  return <Box background={`#fff`} pt={"118px"}>
    <BannerContent {...FadeUp}>
      <BannerTitle>Blockchain Wallet System</BannerTitle>
      <BannerSubtitle>Secure, convenient, and efficient digital asset management tools</BannerSubtitle>
      <BannerButton onClick={() => nav("/quotation")}>TRIAL</BannerButton>
    </BannerContent>
    <AdvantageBox {...FadeUp}>
      <AdvantageTitle>Product advantages</AdvantageTitle>
      <AdvantageContent>
        <HotBox>
          <img src={hotIcon} style={{width: "141px", marginBottom: "36px"}} alt=""/>
          <AdvantageItemTitle>Cold and hot storage</AdvantageItemTitle>
          <AdvantageItemDesc style={{fontSize: "16px"}}>Special cold and hot wallet separation management system, dynamic asset management</AdvantageItemDesc>
        </HotBox>
        {
          advantages.map((item, index) => {
            return <AdvantageItem key={index}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <div>
                  <AdvantageItemTitle>{item.title}</AdvantageItemTitle>
                  <AdvantageItemDesc>{item.desc}</AdvantageItemDesc>
                </div>
                <img src={item.icon} style={{width: "54px", marginLeft: "20px"}} alt=""/>
              </Flex>
            </AdvantageItem>
          })
        }
      </AdvantageContent>
    </AdvantageBox>
    <CaseContainer {...FadeUp}>
      <CaseBox>
        <div style={{width: "472px"}}>
          <CaseTitle>Chain wallet, simple and easy to use</CaseTitle>
          <CaseDesc>Multi chain and multi asset, one wallet, with mainstream assets, now supports Bitcoin, Ethereum, EOS, Poka, Wave Field, BSC, HECO, IOST, Cosmos, Binance, BOS, MOAC and Jingtum</CaseDesc>
        </div>
        <CaseRightBox>
          <img src={case1} style={{width: "234px", marginLeft: "24px"}} alt=""/>
        </CaseRightBox>
      </CaseBox>
      <CaseBox style={{margin: "18px 0"}}>
        <img src={case2} style={{width: "320px", marginLeft: "40px"}} alt=""/>
        <CaseRightBox>
          <CaseTitle>Support for multilingual development</CaseTitle>
          <CaseDesc>
            <OptionItem>The latest and most fun DApps are all here</OptionItem>
            <OptionItem>Find your gaming partner here</OptionItem>
            <OptionItem>Supports 2200+DApp</OptionItem>
          </CaseDesc>
        </CaseRightBox>
      </CaseBox>
      <CaseBox>
        <div style={{width: "350px"}}>
          <CaseTitle>Support for multilingual development</CaseTitle>
          <CaseDesc style={{width: "282px"}}>Supporting multiple languages in mainstream digital asset markets such as China, the UK, Japan, and South Korea; Clear language barriers for creating world-class wallet applications</CaseDesc>
        </div>
        <CaseRightBox>
          <img src={case3} style={{width: "220px", marginLeft: "24px"}} alt=""/>
        </CaseRightBox>
      </CaseBox>
    </CaseContainer>
  </Box>
}
