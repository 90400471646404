import React from 'react';
import styled from "styled-components";
import ServiceableBg from 'src/assets/images/Serviceable.png';
import Grid from "../../components/Box/Grid";
import Flex from "../../components/Box/Flex";
import checkCircleIcon from 'src/assets/svg/check-circle.svg';
import { FadeUp } from "../../styles/aos";

const ServiceableStyle = styled.div`
    height: 668px;
    background: url(${ServiceableBg}) no-repeat center / cover;
    padding-top: 120px;
    box-sizing: border-box;
`;

const Title = styled.h3`
    font-size: 60px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    margin-bottom: 96px;
`;

const ServiceableContent = styled(Grid)`
    width: 912px;
    margin: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 42px;
    color: #fff;
`;

const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 10px;
`;

export default function Serviceable() {

  const items = [
    "DID(Digital ID)",
    "Web3 Domain",
    "Web3 Domain",
    "Cross-chain agreement",
    "Sidechain",
    "DeFi",
    "DEX",
    "Web3",
    "Derivatives",
    "Mining",
    "Distributed storage",
    "Options",
    "Node service",
    "Wallet service",
    "NFT",
    "Meta-universe"
  ];

  return (
    <ServiceableStyle {...FadeUp}>
      <Title>Serviceable items</Title>
      <ServiceableContent>
        {
          items.map((item, index) => {
            return <Flex key={index}>
              <Icon src={checkCircleIcon} />
              <span>{item}</span>
            </Flex>
          })
        }
      </ServiceableContent>
    </ServiceableStyle>
  )
}
