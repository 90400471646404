import React from 'react';
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import Strength1 from 'src/assets/images/dev/Strength1.png';
import Strength2 from 'src/assets/images/dev/Strength2.png';
import Strength3 from 'src/assets/images/dev/Strength3.png';
import decorateImg from "../../assets/images/decorate.png";
import { FadeUp } from "../../styles/aos";

const StrengthsStyle = styled.div`
    position: relative;
    margin-top: 100px;
`;
const Title = styled.div`
    position: relative;
    font-size: 50px;
    font-weight: 700;
    color: #3D3D3D;
    margin-bottom: 86px;
    text-align: center;
`;
const StrengthImgBox = styled.div`
    position: relative;
    width: max-content;
    margin: auto;
`;
const StrengthImg = styled.img`
    width: 368px;
    height: 570px;
    &:not(:last-child){
        margin-right: 50px;
    }
`;

const DecorateIcon = styled.div`
    width: 164px;
    height: 164px;
    background: url(${decorateImg});
    background-size: 100% 100%;
    position: absolute;
    z-index: -1;
`;

export default function Strengths() {


  return (
    <StrengthsStyle {...FadeUp}>
        <Title>
          <span>My strengths</span>
          <DecorateIcon style={{left: "53%", top: "50%", transform: "translate(0, -50%) rotate(70deg)"}} />
        </Title>
        <StrengthImgBox>
          <DecorateIcon style={{width: "98px",height: "98px",right: "-46px", top: "-46px", transform: "rotate(120deg)"}} />
          <DecorateIcon style={{width: "100px",height: "100px",left: "-46px", bottom: "-46px"}} />
          <StrengthImg src={Strength1} />
          <StrengthImg src={Strength2} />
          <StrengthImg src={Strength3} />
        </StrengthImgBox>
    </StrengthsStyle>
  )
}
