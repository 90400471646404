import LogoIcon from "../../assets/images/logo.png"
import Box from "../Box";
import styled from "styled-components";


const Logo = (props: {
  height?: string
}) => <img src={LogoIcon} alt={"Logo"} height={props.height}/>

export default Logo
