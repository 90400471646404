import Box from "../../components/Box";
import React, { useEffect, useState } from "react";
import {
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle,
  DecorateIcon2,
  IconBox,
  IntroBox,
  IntroductionBox,
  IntroItem,
  IntroItemContent,
  IntroItemTitle,
  PaginationBox, PaginationItem,
  ServiceBox,
  ServiceContent,
  ServiceItemDesc,
  ServiceItemTitle,
  ServiceMenu,
  ServiceMenuItem,
  ServiceTitle,
  SolutionsBox,
  SolutionsContent,
  SolutionsGroup,
  SolutionsGroupDesc,
  SolutionsGroupTitle,
  Title
} from "./style";
import lineImg from 'src/assets/images/payment/line.png';
import tradeIcon from 'src/assets/images/payment/trade.svg';
import toolIcon from 'src/assets/images/payment/tool.svg';
import rmIcon from 'src/assets/images/payment/rm.svg';
import vipIcon from 'src/assets/images/payment/vip.svg';
import addIcon from "../../assets/images/lending/add.svg";
import Text from "../../components/Text";
import introSlitBg from 'src/assets/images/payment/introSlitBg.png';
import textTag from "../../assets/svg/textTag.svg"
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";

export default function BlockchainPaymentSystem() {
  const [active, setActive] = useState(0);
  const nav = useNavigate();

  function star() {

  }

  const ServiceArr = [
    {
      icon: tradeIcon,
      title: "Payment Products",
      desc: "Payment tool connected through Songsong, solving the problem of user payment and merchant collection"
    },
    {
      icon: toolIcon,
      title: "Marketing tools",
      desc: `More abundant marketing gameplay and fund allocation More flexible, helping you attract new customers`
    },
    {
      icon: rmIcon,
      title: "Financial Services",
      desc: `Revitalizing fixed funds for easier operation Hanging, more flexible value-added.`
    },
    {
      icon: vipIcon,
      title: "Member Services",
      desc: `Member ServicesMerchant membership service tool to promote Customer retention and repurchase.`
    }
  ];

  const introArr = [
    {
      title: "Decentralization",
      desc: "Simplify transaction process"
    },
    {
      title: "Smart contracts",
      desc: "Increase transaction speed"
    },
    {
      title: "Traceability",
      desc: "The immutability and timestamp characteristics of blockchain technology make transactions traceable"
    },
    {
      title: "Cross border payment",
      desc: "Eliminated barriers to cross-border remittances and payments, making business globalization easier"
    }
  ];
  const introArr2 = [
    {
      title: "Multiple business scenarios",
      desc: "Support multiple business scenarios"
    },
    {
      title: "Omni channel payment",
      desc: "Support multiple channels of payment, including POS payment, face-to-face payment, H5 payment, APP payment, etc"
    },
    {
      title: "Free flow",
      desc: "No limit or regional restrictions, making payments and transfers anytime, anywhere"
    },
    {
      title: "7 * 24-hour quick withdrawal",
      desc: "365 days, anytime available, easy to optimize capital turnover"
    }
  ];

  const SolutionsArr = [
    {
      title: "Catering trade",
      desc: ["The latest and most fun DApps are all here", "Find your gaming partner here"],
    },
    {
      title: "Hotel check-in",
      desc: ["The latest and most fun DApps are all here", "Find your gaming partner here"],
    },
    {
      title: "Hotel check-in",
      desc: ["The latest and most fun DApps are all here", "Find your gaming partner here"],
    },
    {
      title: "E-COMMERCE",
      desc: ["The latest and most fun DApps are all here", "Find your gaming partner here"],
    }
  ];
  useEffect(() => {
    let timer = setTimeout(() => {
      if (active < SolutionsArr.length - 1) {
        setActive(active + 1);
      } else {
        setActive(0);
      }
    }, 1500);

    return () => {
      clearTimeout(timer);
    }
  }, [active]);


  return <Box background={`#fff`}>
    <BannerContent {...FadeUp}>
      <BannerTitle>Blockchain payment system</BannerTitle>
      <BannerSubtitle>Secure and convenient digital payment</BannerSubtitle>
      <BannerButton onClick={() => nav("/quotation")}>TRIAL</BannerButton>
    </BannerContent>
    <ServiceBox {...FadeUp}>
      <ServiceContent>
        <ServiceTitle>Our service capabilities</ServiceTitle>
        <img src={lineImg} style={{height: "270px"}} alt=""/>
        <ServiceMenu>
          {
            ServiceArr.map((item, index) => {
              return <ServiceMenuItem>
                <IconBox>
                  <img src={item.icon} alt=""/>
                </IconBox>
                <div>
                  <ServiceItemTitle>{item.title}</ServiceItemTitle>
                  <ServiceItemDesc>{item.desc}</ServiceItemDesc>
                </div>
              </ServiceMenuItem>
            })
          }
        </ServiceMenu>
      </ServiceContent>
    </ServiceBox>
    <IntroductionBox {...FadeUp}>
      <Title>
        <DecorateIcon2 style={{left: "0", top: "50%", transform: "translate(0, -50%) rotate(70deg)"}} />
        <span style={{position: "relative"}}>Product advantages</span>
      </Title>
      <IntroBox>
        {
          introArr.map((item, index) => {
            return <IntroItem key={index}>
              <IntroItemTitle>
                <img src={addIcon} alt=""/>
                <Text ml={"4px"}>{item.title}</Text>
              </IntroItemTitle>
              <IntroItemContent>{item.desc}</IntroItemContent>
            </IntroItem>
          })
        }
      </IntroBox>
      <img src={introSlitBg} style={{width: "100%", margin: "-20px 0 36px"}} alt=""/>
      <IntroBox>
        {
          introArr2.map((item, index) => {
            return <IntroItem key={index}>
              <IntroItemTitle>
                <img src={addIcon} alt=""/>
                <Text ml={"4px"}>{item.title}</Text>
              </IntroItemTitle>
              <IntroItemContent>{item.desc}</IntroItemContent>
            </IntroItem>
          })
        }
      </IntroBox>
    </IntroductionBox>
    <SolutionsBox {...FadeUp}>
      <Title>
        <DecorateIcon2 style={{left: "0", top: "50%", transform: "translate(0, -50%) rotate(70deg)"}} />
        <span style={{position: "relative"}}>Industry Solutions</span>
      </Title>
      <SolutionsContent>
        {
          SolutionsArr.map((item, index) => {
            return <SolutionsGroup key={index} active={active === index}>
              <img src={textTag} alt=""/>
              <SolutionsGroupTitle>{item.title}</SolutionsGroupTitle>
              {
                item.desc.map((item2, index2) => {
                  return <SolutionsGroupDesc key={index2}>{item2}</SolutionsGroupDesc>
                })
              }
            </SolutionsGroup>
          })
        }
      </SolutionsContent>
      <PaginationBox>
        {
          new Array(SolutionsArr.length).fill("").map((item, index) => {
            return <PaginationItem key={index} active={index === active} />
          })
        }
      </PaginationBox>
    </SolutionsBox>
  </Box>
}
