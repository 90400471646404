import React from "react";
import Box from "../../components/Box";
import goldIcon from 'src/assets/images/decentralized/gold.svg';
import leverIcon from 'src/assets/images/decentralized/lever.svg';
import editLeverIcon from 'src/assets/images/decentralized/editLever.svg';
import contractIcon from 'src/assets/images/decentralized/contract.svg';
import {
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle,
  DecorateIcon2, IntroDesc,
  IntroductionBox, IntroductionContent, IntroductionGrop, IntroductionTag, IntroTitle,
  Title
} from "./style";
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";


export default function DecentralizedTrans() {
  const nav = useNavigate()

  const IntroArr = [
    {
      title: "Single currency mining",
      desc: "Users can pledge and lock their assets to obtain stacking benefits"
    },
    {
      title: "Liquidity mining",
      desc: "Users can provide liquidity on designated DEX and pledge liquidity LP to the platform to generate profits"
    },
    {
      title: "Stablecoin mining",
      desc: "The algorithm of single currency is used to stabilize the Stablecoin, that is, inflation and deflation are all completed through this single currency. Pledge tokens to obtain more tokens during inflation, and destroy tokens to obtain coupons with different premiums during deflation"
    },
    {
      title: "Machine gun pit mining",
      desc: "Machine gun pool refers to a strategy of intelligently scheduling computing power to the optimal revenue currency for different currencies with the same algorithm to enhance user revenue."
    },
    {
      title: "Mining with computing power coins",
      desc: "Decentralized standardized cloud computing power protocol for mining"
    },
    {
      title: "DAO Governance Rewards",
      desc: "In order to motivate users to participate in DAO governance, various incentive measures are taken to enable DAO users to receive more benefits."
    }
  ];

  return <Box background={`#fff`} pt={"168px"}>
    <BannerContent {...FadeUp}>
      <BannerTitle>Decentralized trading and mining</BannerTitle>
      <BannerSubtitle>Decentralized lending refers to matching the Debits and credits and the credit through the center lending agreement, and then transferring assets and completing lending and borrowing immediately after the pledge is confirmed</BannerSubtitle>
      <BannerButton onClick={() => nav("/quotation")}>TRIAL</BannerButton>
    </BannerContent>
    <IntroductionBox {...FadeUp}>
      <Title>
        <DecorateIcon2 style={{left: "-12px", top: "50%", transform: "translate(0, -50%) rotate(70deg)"}} />
        <span style={{position: "relative"}}>Product Function Introduction</span>
      </Title>
      <IntroductionContent>
        {
          IntroArr.map((item, index) => {
            return <IntroductionGrop key={index}>
              <IntroductionTag>0{index + 1}</IntroductionTag>
              <div>
                <IntroTitle>{item.title}</IntroTitle>
                <IntroDesc>{item.desc}</IntroDesc>
              </div>
            </IntroductionGrop>
          })
        }
      </IntroductionContent>
    </IntroductionBox>
  </Box>
}
