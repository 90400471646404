import styled from "styled-components";
import FooterOSIcon from "../../assets/svg/footer_os.svg"
import Flex from "../Box/Flex";
import Logo from "../Logo";
import { CONTENT_WIDTH } from "../../styles";
import Row from "../Row";
import Column from "../Column";
import { navbarSubData } from "../Navbar";
import { useNavigate, Link } from "react-router-dom";
import { MEDIA_WIDTHS } from "../../theme";
import footerLogo from "../../assets/svg/footer_logo.svg"
import textTag from "../../assets/svg/textTag.svg"
import foorterBg from 'src/assets/images/foorterBg.png';
import { ButtonDefault } from "../Button";
import Grid from "../Box/Grid";
import Box from "../Box";


const Wrapper = styled.div`
    position: relative;
    z-index: 0;
`

const ContactBox = styled.div`
    width: 100%;
    padding-top: 94px;
    background: #1C1929;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    text-align: center;
`;

const ContactButton = styled(ButtonDefault)`
    width: 242.42px;
    height: 66px;
    border-radius: 4px;
    opacity: 1;
    background: linear-gradient(270deg, #37FD6B 8%, #63FF8A 100%);
    box-shadow: 0px 8px 10px 0px rgba(41, 65, 255, 0.2);
    font-size: 28px;
    font-weight: 700;
    color: #333333;
    margin-top: 68px;
    cursor: pointer;
`;

const SplitBox = styled.div`
    width: 100%;
    height: 55px;
    background: url(${foorterBg});
    background-size: 100% 100%;
`;

const BottomBox = styled(Flex)`
    justify-content: center;
    color: #191933;
    background: rgba(211, 211, 211, 0.3);
    padding: 62px 0 52px;
`;
const LogoContainer = styled(Flex)`
    flex-direction: column;
    align-items: center;
    margin-right: 188px;
`;
const LogoBox = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #191933;
`;
const LearnImg = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 6px;
    opacity: 1;
    background: #FFFFFF;
    margin: 22px 0 10px;
`;
const BottomTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
`;

const Anchor = styled.a`
    color: #191933;
`;
const AnchorText = styled.span`
    color: #191933;
`;

const Footer = () => {
    const nav = useNavigate()

    return <Wrapper>
        {/*<Flex flexDirection={"column"} maxWidth={`${MEDIA_WIDTHS.xl}px`} width={"100%"}>*/}
        {/*  */}
        {/*</Flex>*/}
        {
            window.location.pathname.startsWith("/quotation")
                ? null
                : <>
                    <ContactBox>
                        <Flex alignItems={"flex-end"} justifyContent={"center"}>
                            <img src={textTag} alt=""/>
                            <div>To learn more about Innoweb3, please provide your contact</div>
                        </Flex>
                        <div style={{ paddingLeft: "68px" }}>information. We will provide you with a detailed introduction and</div>
                        <div>services based on your business</div>
                        <ContactButton onClick={() => nav("/quotation")}>Contact US</ContactButton>
                    </ContactBox>
                    <SplitBox/>
                </>
        }
        <BottomBox>
            <LogoContainer>
                <LogoBox href="/">
                    <Logo height={`46px`}/>
                    <span style={{marginLeft: "10px"}}>Innoweb3</span>
                </LogoBox>
                {/*<LearnImg />*/}
                {/*<span style={{fontSize: "14px"}}>Learn more</span>*/}
            </LogoContainer>
            <div style={{paddingTop: "10px"}}>
                <Box mb={"24px"}>
                    <BottomTitle>Customer Stories</BottomTitle>
                    <Grid gridColumnGap={"20px"} gridAutoFlow={"column"} mt={"20px"}>
                        {/*<Anchor href={"https://dxq.xsdx.xyz/"} target={"_blank"}><AnchorText>DXQ Exchange</AnchorText></Anchor>*/}
                        <Link to={"/product/dex"} ><AnchorText>DXQ Exchange</AnchorText></Link>
                        <Link to={"/product/decentralized"} ><AnchorText>Decentralized lending</AnchorText></Link>
                        <Link to={"/product/cross-chain"} ><AnchorText>Cross chain bridge</AnchorText></Link>
                        <Link to={"/product/transaction-mining"} ><AnchorText>Decentralized trading and mining</AnchorText></Link>
                        <Link to={"/product/nft-trading"} ><AnchorText>NTF Trading System</AnchorText></Link>
                    </Grid>
                </Box>
                <div>
                    <BottomTitle>Contact Us</BottomTitle>
                    <Box mt={"10"} lineHeight={"28px"}>
                        <div>corporate name：HONGKONG ZHAOBI LIMITED</div>
                        <div>mailbox：innoweb3@proton.me</div>
                        {/*<div>address：</div>*/}
                    </Box>
                </div>
            </div>
        </BottomBox>
    </Wrapper>
}

export default Footer
