import React from 'react';
import styled from "styled-components";
import publicChain from 'src/assets/images/publicChain.png';
import { FadeUp } from "../../styles/aos";


const PublicChainsStyle = styled.div`
    height: 632px;
    background: #000000;
    text-align: center;
    padding-top: 66px;
    box-sizing: border-box;
`;

const Title = styled.h3`
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 64px 0;
`;

const ChainStyle = styled.img`
    width: 962px;
`;

export default function PublicChains() {

  return (
    <PublicChainsStyle {...FadeUp}>
      <Title>The public chains we can serve</Title>
      <ChainStyle src={publicChain} />
    </PublicChainsStyle>
  )
}
