import Box from "../../components/Box";
import styled from "styled-components";
import BGImgIcon from "../../assets/svg/chainDevelopment/chain_developer_bg_icon.svg"
import BG from "../../assets/svg/chainDevelopment/chain_dev_banner_bg.svg"
import Column from "../../components/Column";
import {CONTENT_WIDTH} from "../../styles";
import {ButtonDefault} from "../../components/Button";
import {MEDIA_WIDTHS} from "../../theme";
import {ConsultNowButton, Cover} from "../Home/Banner";
import Flex from "../../components/Box/Flex";
import {FadeUp} from "../../styles/aos";
import {useNavigate} from "react-router-dom";
import bannerBg from 'src/assets/images/dev/bannerBg.png';

const BGWrapper = styled.div`
  //background: linear-gradient(131deg, rgba(121, 117, 131, 0.20) 5%, rgba(121, 117, 131, 0.20) 5%, rgba(54, 53, 103, 0.20) 63%, rgba(54, 53, 103, 0.20) 63%), rgba(49, 48, 54, 0.3) url(${BG}) no-repeat center center / cover;
  //backdrop-filter: blur(14px);
  padding: calc(130px + 64px) 32px 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #180c55 url(${bannerBg});  
  background-size: 100% 100%;  
  ${({theme}) => theme.mediaQueries.md} {
    padding: calc(64px + 32px) 24px 64px;
    min-height: auto;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Wrapper = styled.div`
  //margin: 0 auto;
  max-width: ${MEDIA_WIDTHS.xl}px;
  width: 100%;
  display: flex;
  grid-gap: 80px;
  align-items: center;
  justify-content: space-between;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    grid-gap: 16px;
  }
`

const Title = styled(Box)`
  font-size: 48px;

  ${({theme}) => theme.mediaQueries.sm} {
    font-size: 32px;
  }
`
const DescBox = styled(Box)`
    background: rgba(21, 16, 52, 0.6);
    box-sizing: border-box;
    border: 4px solid #37FD6B;
    margin-top: 60px;
    padding: 40px 44px;
`;
const Desc = styled(Box)`
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  //position: relative;
  //padding-left: 24px;
  text-align: center;

  ${({theme}) => theme.mediaQueries.md} {
    text-align: center;
  }

  //&::before {
  //  content: '';
  //  display: inline-block;
  //  background: #6C55FA;
  //  width: 12px;
  //  height: 12px;
  //  border-radius: 6px;
  //  position: absolute;
  //  left: 0;
  //  top: calc(50% - 7px);
  //}
`
const WrapperContent = styled(Flex)`
  flex-direction: row;
  max-width: ${MEDIA_WIDTHS.xl}px;
  padding: 100px 0;
  width: 100%;
  gap: 200px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
    align-items: start;
    gap: 64px;
  }
`
const TextBox = styled(Column)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    ${({ theme }) => theme.mediaQueries.md} {
        text-align: center;
    }

    overflow: hidden;
`

export const Cover2 = styled.img`
  //display: none;
  //visibility: hidden;
  max-width: 600px;
  width: 100%;

  ${({theme}) => theme.mediaQueries.sm} {
    visibility: visible;
    max-width: 242px;
  }
`

const SubTitle = styled(Box)`
  font-size: 24px;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

export default function Banner() {
  const nav = useNavigate()

  return <BGWrapper>
    <Wrapper {...FadeUp}>
      {/*<Cover2 src={BGImgIcon}/>*/}
      <TextBox>
        <Title>One stop public chain service platform for easy</Title>
        <Flex justifyContent={"center"}>
          <Title>customization of&nbsp;</Title>
          <Title style={{color: "#37FD6B"}}>blockchain systems</Title>
        </Flex>
        <DescBox>
          <Desc
            marginTop={`32px`}
            gridGap={`16px`}>
            Focus on the development of technical services in the blockchain industry, provide one-click completion of
            blockchain application development and deployment; provide your customers with transparent and credible
            services.

            The platform can provide users with services such as anti-counterfeiting traceability, token issuance,
            shared ledgers, and supply chain management.

            Enterprises can easily customize the most suitable blockchain system for enterprises without high
            development costs.
          </Desc>
          <Box marginTop={`52px`}>
            <ConsultNowButton
              onClick={() => nav("/quotation")}
            >
              Contact US
            </ConsultNowButton>
          </Box>
        </DescBox>
      </TextBox>
    </Wrapper>
  </BGWrapper>
}
