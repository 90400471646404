import React from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import Flex from "../../components/Box/Flex";
import decorateImg from 'src/assets/images/decorate.png';
import EcologicalBg from 'src/assets/images/EcologicalBg.png';
import addIcon from 'src/assets/svg/add.svg';
import Grid from "../../components/Box/Grid";
import Technical from "./Technical";
import { FadeUp } from "../../styles/aos";

const EcologicalStyle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 120px auto 0;
`;

const TitleBox = styled.h3`
    font-size: 60px;
    font-weight: 700;
    line-height: 98px;
    color: #3D3D3D;
    padding-left: 28px;
    background: url(${decorateImg}) no-repeat;
    background-size: 98px 98px;
    margin: 0;
`;

const EcologicalContent = styled.div`
    padding: 80px 18px 0;
    background: url(${EcologicalBg}) no-repeat right top;
    background-size: 582px 440px;
`;

const Group = styled.div`
    margin-bottom: 18px;
`;
const GroupHeader = styled(Flex)`
    margin-bottom: 8px;
`;
const GroupTitle = styled.h3`
    font-size: 20px;
    font-weight: 700;
    color: #3D3D3D;
    margin: 0 0 0 16px;
`;
const GroupContent = styled.div`
    padding-left: 34px;
    font-size: 14px;
    line-height: 22px;
`;
const EcologicalBottom = styled(Grid)`
    min-height: 182px;
    padding: 28px 12px 0 18px;
    background: #FFFFFF;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr auto;
    grid-column-gap: 36px;
    border-radius: 12px;
    margin-top: 30px;
`;

export default function Ecological() {

  return (
    <EcologicalStyle {...FadeUp}>
      <TitleBox>Ecological layout</TitleBox>
      <EcologicalContent>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>Blockchain service platform</GroupTitle>
          </GroupHeader>
          <GroupContent style={{width: "450px"}}>The professional docking platform for blockchain projects makes them simple, convenient, and efficient.</GroupContent>
        </Group>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>Web3 Ecology</GroupTitle>
          </GroupHeader>
          <GroupContent style={{width: "450px"}}>Web3.0 infrastructure: DID, domain name, social media, DAO, privacy, storage and data, gaming, etc.</GroupContent>
        </Group>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>Public chain</GroupTitle>
          </GroupHeader>
          <GroupContent style={{width: "450px"}}>Building a decentralized, efficient, and user friendly financial ecosystem based on the PoS consensus protocol.</GroupContent>
        </Group>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>side  chain</GroupTitle>
          </GroupHeader>
          <GroupContent style={{width: "450px"}}>The Zru based L2 extension solution uses Zero-knowledge proof to provide high throughput and high security.</GroupContent>
        </Group>
      </EcologicalContent>
      <EcologicalBottom>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>Cross chain protocol</GroupTitle>
          </GroupHeader>
          <GroupContent style={{}}>Build a new generation of cross chain service infrastructure to achieve value transfer between blockchain internet chains.</GroupContent>
        </Group>
        <Group>
          <GroupHeader>
            <img src={addIcon} alt=""/>
            <GroupTitle>Web3 Ecology</GroupTitle>
          </GroupHeader>
          <GroupContent style={{}}>On the basis of SDK, integrate the functions of zk roll up and develop corresponding L2 (using zk roll up) for popular main chains.</GroupContent>
        </Group>
        <Group>
          <GroupHeader>
            <div><img src={addIcon} style={{marginTop: "2px"}} alt=""/></div>
            <GroupTitle>Decentralized Finance DeFi</GroupTitle>
          </GroupHeader>
          <GroupContent style={{}}>Decentralized financial applications</GroupContent>
        </Group>
        <Group style={{width: "274px"}}>
          <GroupHeader>
            <div><img src={addIcon} style={{marginTop: "2px"}} alt=""/></div>
            <GroupTitle>Blockchain applications</GroupTitle>
          </GroupHeader>
          <GroupContent style={{}}>DID, domain name, DEX, CEX, derivatives, options, node services, wallet services, blockchain NFT services.</GroupContent>
        </Group>
      </EcologicalBottom>
      <Technical />
    </EcologicalStyle>
  )
}
