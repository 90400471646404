import {
  Banner,
  BannerButton,
  BannerContent,
  BannerDesc,
  BannerTitle,
  CrossChainStyle,
  IntroBox, IntroContent, IntroDesc, IntroductionBox,
  IntroGroup, IntroTitle, SplitBox
} from "./style";
import Flex from "../../components/Box/Flex";
import SplitLine from "../DecentralizedTrans/SplitLine";
import React from "react";
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";

export default function CrossChainBridge() {
  const nav = useNavigate()

  const introArr1= [
    {
      title: "Cross chain atomic mapping",
      desc: "Cross chain atomic mapping is the process of locking assets in one chain and casting an equal amount of assets onto another chain in a 1:1 ratio, achieving rapid matching between assets on another chain."
    },
    {
      title: "One click cross chain assets",
      desc: "One click asset cross chain experience adapted to multiple application scenarios"
    },
    {
      title: "Reduce transaction costs",
      desc: "Reduced gas fees while improving transaction speed"
    }
  ];

  const introArr2= [
    {
      title: "Convenient and fast",
      desc: "The assets supported by single chain are thin, while the assets supported by cross chain bridges are more"
    },
    {
      title: "Cross chain aggregation application Swap",
      desc: "It aggregates the liquidity of assets on different chains to build a cross chain transaction pool, in which users can complete asset exchanges on different chains."
    },
    {
      title: "Update account balance",
      desc: "And the bridge will be notified of new account balance information, which can be used to assist with withdrawals"
    }
  ];

  return <CrossChainStyle>
    <Banner>
      <BannerContent {...FadeUp}>
        <Flex>
          <BannerTitle>Cross chain</BannerTitle>
          <BannerTitle>bridge</BannerTitle>
        </Flex>
        <BannerDesc>
          A bridge channel is provided between two different public chains to trust the original assets on this bridge. At the same time, one chain can obtain the asset transactions on the other through the Oracle machine function and map to the other chain in a 1:1 ratio.
        </BannerDesc>
        <BannerButton onClick={() => nav("/quotation")}>TRAIL</BannerButton>
      </BannerContent>
    </Banner>
    <IntroductionBox {...FadeUp}>
      <IntroBox>
        {
          introArr1.map((item, index) => {
            return <IntroGroup key={index}>
              <IntroContent>
                <IntroTitle>{item.title}</IntroTitle>
                <IntroDesc>{item.desc}</IntroDesc>
              </IntroContent>
              {
                index !== introArr2.length - 1
                  ? <SplitLine style={{height: "120px", marginTop: "18px"}} />
                  : null
              }
            </IntroGroup>
          })
        }
      </IntroBox>
      <SplitBox {...FadeUp} />
      <IntroBox {...FadeUp}>
        {
          introArr2.map((item, index) => {
            return <IntroGroup key={index}>
              <IntroContent>
                <IntroTitle>{item.title}</IntroTitle>
                <IntroDesc>{item.desc}</IntroDesc>
              </IntroContent>
              {
                index !== introArr2.length - 1
                  ? <SplitLine style={{height: "120px", marginTop: "18px"}} />
                  : null
              }
            </IntroGroup>
          })
        }
      </IntroBox>
    </IntroductionBox>
  </CrossChainStyle>
}
