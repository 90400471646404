import React from 'react';
import styled from "styled-components";

import chains from 'src/assets/images/dev/chains.png';
import { FadeUp } from "../../styles/aos";

const UnderlyingStyle = styled.div`
    text-align: center;
    margin-top: 74px;
`;

const Title = styled.h3`
    font-size: 50px;
    font-weight: 700;
    margin: 0 auto 50px;
    text-align: center;
    color: #3D3D3D;
`;

export default function Underlying() {

  return (
    <UnderlyingStyle {...FadeUp}>
      <Title>Underlying architecture</Title>
      <img src={chains} style={{width: "858px"}} alt=""/>
    </UnderlyingStyle>
  )
}
