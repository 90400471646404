import addIcon from 'src/assets/images/lending/add.svg';
import {
  BannerButton,
  BannerContent,
  BannerSubtitle,
  BannerTitle, IntroContentBox,
  IntroHeader,
  IntroHeaderContent, IntroHeaderTitle, IntroItem, IntroItemContent, IntroItemTitle,
  LendingStyle, SplitLineStyle
} from "./style";
import Text from "../../components/Text";
import { FadeUp } from "../../styles/aos";
import { useNavigate } from "react-router-dom";

export default function DecentralizedLending() {
  const nav = useNavigate()

  return <LendingStyle>
    <BannerContent {...FadeUp}>
      <BannerTitle>Decentralized lending</BannerTitle>
      <BannerSubtitle>Decentralized lending refers to matching the Debits and credits and the credit through the center lending agreement, and then transferring assets and completing lending and borrowing immediately after the pledge is confirmed</BannerSubtitle>
      <BannerButton onClick={() => nav("/quotation")}>TRIAL</BannerButton>
    </BannerContent>
    <IntroHeader {...FadeUp}>
      <IntroHeaderContent>
        <IntroHeaderTitle>Product Function Introduction</IntroHeaderTitle>
      </IntroHeaderContent>
    </IntroHeader>
    <IntroContentBox {...FadeUp}>
      <SplitLineStyle style={{left: "334px", top: "114px", transform: "rotate(-45deg)"}} />
      <SplitLineStyle style={{right: "334px", top: "114px", transform: "rotate(45deg)"}} />
      <SplitLineStyle style={{left: "334px", bottom: "114px", transform: "rotate(-135deg)"}} />
      <SplitLineStyle style={{right: "334px", bottom: "114px", transform: "rotate(135deg)"}} />

      <IntroItem style={{top: "186px"}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>Synthetic Assets</Text>
        </IntroItemTitle>
        <IntroItemContent>Users can cast their own assets into platform z-Token assets through the platform's synthetic assets. A portion of the pledged assets may be used to leverage mining users' borrowing sources to obtain deposit income. Users can redeem their z-Token holdings at any time and convert them into pre synthetic assets.</IntroItemContent>
      </IntroItem>
      <IntroItem style={{top: "526px"}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>Liquidity leveragemining</Text>
        </IntroItemTitle>
        <IntroItemContent>
          The platform will provide a series of liquidity LP mining pools for users to choose from, such as HT/USDT, ETH/USDT, BTC/USDT, etc. Users can invest in one of the LP currency pairs and borrow platform assets to balance LP for liquidity mining.
        </IntroItemContent>
      </IntroItem>
      <IntroItem style={{top: "100px", left: "50%", transform: "translate(-50%, 0)"}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>Non-destructive mining</Text>
        </IntroItemTitle>
        <IntroItemContent style={{width: "330px"}}>
          Users can pledge and lock their synthetic assets (z-Token) to obtain stacking benefits
        </IntroItemContent>
      </IntroItem>
      <IntroItem style={{bottom: "72px", left: "50%", transform: "translate(-50%, 0)"}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>Clearing Pool</Text>
        </IntroItemTitle>
        <IntroItemContent style={{width: "330px"}}>
          Clearing line refers to the risk control clearing triggered when the user's quota usage rate reaches a specific value. The platform will sell its pledged assets on the platform to repay the assets lent to users by the platform. When a user's loan is liquidated, the user does not need to repay the assets lent to him by the platform.
        </IntroItemContent>
      </IntroItem>

      <IntroItem style={{top: "246px", right: 0}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>Liquidity mining</Text>
        </IntroItemTitle>
        <IntroItemContent>
          Users can provide liquidity on designated DEX and pledge liquidity LP to the platform to generate profits
        </IntroItemContent>
      </IntroItem>
      <IntroItem style={{top: "526px", right: 0}}>
        <IntroItemTitle>
          <img src={addIcon} alt=""/>
          <Text ml={"4px"}>DAO Governance Rewards</Text>
        </IntroItemTitle>
        <IntroItemContent>
          In order to motivate users to participate in DAO governance, various incentive measures are taken to enable DAO users to receive more benefits.
        </IntroItemContent>
      </IntroItem>
    </IntroContentBox>
  </LendingStyle>
}
