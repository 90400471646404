import Banner from "./Banner";
import OurAdvantage from "./OurAdvantage";
import Box from "../../components/Box";
import OurDevelopmentAdvantage from "./OurDevelopmentAdvantage";
import ConsultNow from "../../views/ConsultNow";
import UnderlyingArchitecture from "./UnderlyingArchitecture";
import React, {useEffect} from "react";
import AOS from "aos";
import OurAdvantage2 from "./OurAdvantage2";
import BG from "./BG";
import Strengths from "./Strengths";
import Product from "./Product";
import Underlying from "./Underlying";

export default function ChainDevelopment() {

  return <Box
    mb={"50px"}
    // background={`#0C0E11`}
    position={"relative"}
  >
    {/*<BG/>*/}
    <Banner/>
    <Strengths />
    <Product />
    <Underlying />
    {/*<OurAdvantage/>*/}
    {/*<OurAdvantage2/>*/}
    {/*<OurDevelopmentAdvantage/>*/}
    {/*<UnderlyingArchitecture/>*/}
    {/*<Box marginTop={`64px`}>*/}
    {/*  <ConsultNow/>*/}
    {/*</Box>*/}
  </Box>
}
