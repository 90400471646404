import Section from "../../components/Section";
import Flex from "../../components/Box/Flex";
import Box from "../../components/Box";
import styled from "styled-components";
import Text from "../../components/Text";
import Row from "../../components/Row";
import Grid from "../../components/Box/Grid";
import {CoreBusinessData, ICoreBusinessData} from "./CoreBusinessData";
import {FadeUp} from "../../styles/aos";
import {useNavigate} from "react-router-dom";
import gridImg from 'src/assets/images/image.png';
import decorateImg from 'src/assets/images/decorate.png';
import { BOX_CONTENT_WIDTH } from "../../styles";

const GridWrapper = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  ${({theme}) => theme.mediaQueries.md} {
    grid-template-columns: 1fr;
  }
`

const GridContainer = styled.div`
    position: relative;
`;

const GirdBgImg = styled.div`
    width: 273px;
    height: 256px;
    background: url(${gridImg});
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
`;

const DecorateIcon = styled.div`
    width: 98px;
    height: 98px;
    background: url(${decorateImg});
    background-size: 100% 100%;
    position: absolute;
    left: -40px;
    top: -54px;
`;

const CoreBusiness2Style = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: auto;
`;

export default function CoreBusiness2() {
  return <CoreBusiness2Style>
    <Section
      contentStyle={{padding: "0 8px", boxSizing: "border-box"}}
      title={"BUSINESS"}
      subtitle={<div style={{width: "700px", margin: "auto", lineHeight: "30px"}}>Supports multiple business products and allows for free selection and combination</div>}
    >
      <GirdBgImg />
      <GridContainer>
        <DecorateIcon />
        <DecorateIcon style={{width: "76px", height: "76px", top: "50%", right: "-40px", left: "unset", transform: "translate(0,-50%) rotate(180deg)"}} />
        <DecorateIcon style={{width: "36px", height: "36px", top: "unset", bottom: 0, left: "74%", transform: "translate(0,100%) rotate(180deg)"}} />
        <GridWrapper
          gridGap={"30px"}
        >
          {
            CoreBusinessData.map((it, idx) => {
              return <Cell data={it} key={idx} full={false} index={idx}/>
            })
          }
        </GridWrapper>
      </GridContainer>
    </Section>
  </CoreBusiness2Style>
}

const CellIcon = styled.img<{
  full: boolean
}>`
  transition: all .2s ease-out;
  // width: ${({full}) => full ? "243px" : "155px"};
  width: 32px;
  margin-left: ${({full}) => full ? "28px" : 0};

  ${({theme}) => theme.mediaQueries.md} {
    width: 155px;
    margin-left: 0;
  }
`

const CellBox = styled(Flex)<{
  full: boolean
}>`
  background: #fff;
  flex-direction: ${(props) => props.full ? "row" : "column"};
  justify-content: space-between; 
  border-radius: 4px;
  grid-column: ${(props) => props.full ? "1 / 4" : "auto"};
  min-height: min-content;
  height: 100%;
  color: #333;
  box-shadow: 0px 8px 20px 0px rgba(17, 54, 103, 0.1);
  overflow: hidden;
  &:hover ${CellIcon} {
    transform: scale(1.05);
  }

  ${({theme}) => theme.mediaQueries.md} {
    grid-column: auto;
    flex-direction: row;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    grid-column: auto;
    flex-direction: column;
    align-items: start;
  }
`

const CellRight = styled(Flex)<{
  full: boolean
}>`
  flex-direction: column;
  justify-content: center;
  max-width: ${({full}) => full ? "490px" : "100%"};
  margin-left: ${({full}) => full ? "auto" : "0"};
  padding-left: ${({full}) => full ? "32px" : 0};

  ${({theme}) => theme.mediaQueries.md} {
    max-width: 490px;
    margin-left: auto;
    padding-left: 32px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    margin-left: 0;
    padding-left: 0;
  }
`

const CellHeader = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 24px;
`;

const CellBottom = styled.div`
    height: 108px; 
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    background: #020714;
    color: #fff;
`;
const CellBottomContent = styled.div`
    
`;


const LearnMoreText = styled(Text)``

const LearnMore = styled(Flex)`
  flex-direction: row;
  margin-top: 32px;
  align-items: center;
  cursor: pointer;
  align-self: start;

  &:hover ${LearnMoreText} {
    color: #6C55FA;
    transition: all .2s;
  }

  &:hover img {
    filter: drop-shadow(100vw 0 0 #6C55FA);
    transform: translate(-100vw);
  }
`

const Cell = (props: {
  data: ICoreBusinessData,
  full: boolean,
  index: number
}) => {
  const nav = useNavigate()

  return <CellBox full={props.full} {...FadeUp}>
    <div>
      <CellHeader>
        <CellIcon src={props.data.largeIcon} alt={props.data.title} full={props.full}/>
        <Text
          color={"#333"}
          fontSize={"16px"}
          fontWeight={500}
          ml={"6px"}
        >{props.data.title}</Text>
      </CellHeader>
      <CellRight full={props.full}>
        <Text
          color={"#333"}
          fontSize={"12px"}
          fontWeight={400}
          lineHeight={"20px"}
          mt={"16px"}
          pl={"20px"}
          pr={"12px"}
        >{props.data.desc}</Text>
        {/*<Flex flex={1}/>*/}
      </CellRight>
    </div>
    <div>
      <LearnMore
        mb={"16px"}
        pl={"20px"}
        onClick={() => {
          nav(props.data.navTo)
        }}
      >
        <LearnMoreText
          color={"#1DA741"}
          fontSize={"14px"}
          fontWeight={700}
          marginRight={"12px"}
        >Trial</LearnMoreText>
        <img height={"9px"} src={require("../../assets/svg/arrow.svg").default} alt={""}/>
      </LearnMore>
      <CellBottom>
        <CellBottomContent>
          {
            props.data.bottomText.map((item, index) => {
              return <div style={{lineHeight: "20px", paddingLeft: props.index > 3 ? "14px" : "22px"}} dangerouslySetInnerHTML={{__html: item}}></div>
            })
          }
        </CellBottomContent>
      </CellBottom>
    </div>
  </CellBox>
}
