import Box from "../Box";
import Flex from "../Box/Flex";
import styled from "styled-components";
import {createPortal} from "react-dom";
import {useEffect, useState} from "react";
import {navbarSubData} from "./index";
import closeIcon from "../../assets/svg/navbar/nav_close.svg"
import Row from "../Row";
import {useNavigate} from "react-router-dom";
import expandIcon from "../../assets/svg/navbar/nav_expand_arrow.svg"

const Mark = styled.div<{
  display: boolean
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({display}) => display ? `rgba(0, 0, 0, .5)` : `transparent`};
  z-index: 20;
  display: none;
  align-items: stretch;
  justify-content: end;
  visibility: ${({display}) => display ? `visible` : `hidden`};
  transition: all .2s ease-out;

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

const FixedWrapper = styled.div<{
  display: boolean
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  //height: 100%;
  width: 340px;
  max-width: 100%;
  background: #fff;
  transform: translateX(${({display}) => display ? "0" : "100%"});
  
  //margin-right: ${({display}) => display ? `0` : `-340px`};
  transition: all .2s ease-out;
`

const NavLink = styled.div<{
  isSelected?: boolean
}>`
  height: 60px;
  line-height: 60px;
  padding-left: 24px;
  padding-right: 12px;
  color: ${({isSelected}) => isSelected ? `#6C55FA` : `#151620`};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
`

const NavSubLink = styled(NavLink)<{
  isSelected?: boolean
}>`
  //color: #24292f80;
  color: ${({isSelected}) => isSelected ? `#6C55FA` : `#24292f80`};
  height: 44px;
  line-height: 44px;
`

const Hr = styled(Box)`
  display: block;
  height: 1px;
  background: #D8D8D8;
`

const ExpandIcon = styled.img<{
  expand: boolean
}>`
  display: inline-block;
  transform: rotate(${({expand}) => expand ? `0` : `-90deg`});
`

export default function NavbarMd(props: {
  _display: boolean,
  dismiss?(): void
}) {
  const [maskElement, setMaskElement] = useState<HTMLElement | null>(null)
  const [serviceExpand, setServiceExpand] = useState(true)
  const nav = useNavigate()

  useEffect(() => {
    const handleClickOutside = ({target}: Event) => {
      if (target instanceof Node && target === maskElement) {
        props.dismiss?.()
      }
    }
    if (maskElement !== null) {
      document.addEventListener("click", handleClickOutside)
    }
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [props, maskElement])

  const navTo = (path: string) => {
    props.dismiss?.()
    nav(path)
  }

  return createPortal(<Mark ref={setMaskElement} display={props._display}>
    <FixedWrapper display={props._display} onClick={() => console.log(``)}>
      <Row
        height={`64px`}
        paddingX={`24px`}
        background={`#F6F8FA`}
        justifyContent={`end`}
      >
        <Box onClick={props.dismiss} style={{cursor: "pointer"}}>
          <img src={closeIcon} width={`24px`} height={`24px`}/>
        </Box>
      </Row>
      <NavLink isSelected={window.location.pathname === "/"} onClick={() => navTo("/")}>HOME</NavLink>
      <Hr/>
      <NavLink
        isSelected={window.location.pathname === "/development"}
        onClick={() => navTo("/development")}
      >
        CHAIN DEVELOPMENT
      </NavLink>
      <Hr/>
      {/*<NavLink onClick={() => setServiceExpand(!serviceExpand)}>PRODUCT SERVICE</NavLink>*/}
      <Row onClick={() => setServiceExpand(!serviceExpand)}>
        <NavLink>PRODUCT SERVICE</NavLink>
        <ExpandIcon expand={serviceExpand} src={expandIcon}/>
      </Row>
      {
        navbarSubData.map((it) => {
          return serviceExpand ?
            <NavSubLink
              key={it.title}
              onClick={() => navTo(`/product${it.link}`)}
              isSelected={window.location.pathname === `/product${it.link}`}
            >{it.title}</NavSubLink> : <Box/>
        })
      }
      <Hr/>
      <NavLink
        isSelected={window.location.pathname.startsWith("/pricing")}
        onClick={() => navTo("/pricing")}>PRICING</NavLink>
      <Hr/>
    </FixedWrapper>
  </Mark>, document.body)
}
