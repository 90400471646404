import React from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import AdvantagesBg from 'src/assets/images/AdvantagesBg.png';
import tuandui from 'src/assets/svg/advant/tuandui.svg';
import server from 'src/assets/svg/advant/server.svg';
import jingji from 'src/assets/svg/advant/jingji.svg';
import st from 'src/assets/svg/advant/st.svg';
import Grid from "../../components/Box/Grid";
import { FadeUp } from "../../styles/aos";

const AdvantagesStyle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 170px auto 0;
    
`;
export const AdvantagesBox = styled.div`
    height: 600px;
    background: url(${AdvantagesBg});
    background-size: 100% 100%;
    padding: 70px 48px 0;
    box-sizing: border-box;
`;
const AdvantagesTitle = styled.h3`
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
`;
const AdvantagesContent = styled(Grid)`
    grid-template-columns: 1fr 1fr 1fr auto;
`;
const AdvantagesGroup = styled.div`
    padding-right: 12px;
`;

const AdvantageLabel = styled.div`
    position: relative;
    font-size: 80px;
    font-weight: 900;
    color: rgba(42, 213, 87, 0.2);
`;
const AdvantageLabelIcon = styled.img`
    position: absolute;
    bottom: 24px;
    left: -20px;
`;

const StepBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
`;

const StepPoint = styled.div`
    width: 16px;
    height: 16px;
    background: #2AD557;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
    margin-right: 12px;
    border-radius: 50%;
`;
const StepLine = styled.div`
    flex: 1;
    height: 1px;
    background: #2AD557;
`;

const AdantageGroupTitleBox = styled.h3`
    height: 74px;
    margin-top: 28px;
`;
const AdantageGroupTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #151034;
`;
const AdantageGroupContent = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding-right: 60px;
`;

export default function Advantages() {

  return (
    <AdvantagesStyle {...FadeUp}>
      <AdvantagesTitle>Our Advantages</AdvantagesTitle>
      <AdvantagesBox>
        <AdvantagesContent>
          <AdvantagesGroup>
            <AdvantageLabel>
              <span>1</span>
              <AdvantageLabelIcon style={{bottom: "16px"}} src={tuandui} />
            </AdvantageLabel>
            <StepBox>
              <StepPoint />
              <StepLine />
            </StepBox>
            <AdantageGroupTitleBox>
              <AdantageGroupTitle>Team</AdantageGroupTitle>
              <AdantageGroupTitle>Advantage</AdantageGroupTitle>
            </AdantageGroupTitleBox>
            <AdantageGroupContent>
              Strong technical background, Team members previously worked for Huawei, Tencent, and well-known blockchain projects , with many years of experience in large-scale system development and blockchain crypto application development
            </AdantageGroupContent>
          </AdvantagesGroup>
          <AdvantagesGroup>
            <AdvantageLabel>
              <span>2</span>
              <AdvantageLabelIcon src={server} />
            </AdvantageLabel>
            <StepBox>
              <StepPoint />
              <StepLine />
            </StepBox>
            <AdantageGroupTitleBox>
              <AdantageGroupTitle>One stop </AdantageGroupTitle>
              <AdantageGroupTitle>service</AdantageGroupTitle>
            </AdantageGroupTitleBox>
            <AdantageGroupContent>
              Rich accumulation of underlying blockchain technology cases, providing over 200 blockchain technology solutions globally, and implementing standardized and professional projects to safeguard various encryption projects
            </AdantageGroupContent>
          </AdvantagesGroup>
          <AdvantagesGroup>
            <AdvantageLabel>
              <span>3</span>
              <AdvantageLabelIcon src={st} />
            </AdvantageLabel>
            <StepBox>
              <StepPoint />
              <StepLine />
            </StepBox>
            <AdantageGroupTitleBox>
              <AdantageGroupTitle>Open ecological </AdantageGroupTitle>
              <AdantageGroupTitle>layout</AdantageGroupTitle>
            </AdantageGroupTitleBox>
            <AdantageGroupContent>
              Open cooperation, work together to provide open and innovative blockchain ecosystem services and business cooperation models, and promote blockchain technology Web3 infrastructure
            </AdantageGroupContent>
          </AdvantagesGroup>
          <AdvantagesGroup style={{width: "184px"}}>
            <AdvantageLabel>
              <span>4</span>
              <AdvantageLabelIcon src={jingji} />
            </AdvantageLabel>
            <StepBox>
              <StepPoint />
              <StepLine />
            </StepBox>
            <AdantageGroupTitleBox>
              <AdantageGroupTitle>Rich industry </AdantageGroupTitle>
              <AdantageGroupTitle>experience</AdantageGroupTitle>
            </AdantageGroupTitleBox>
            <AdantageGroupContent style={{paddingRight: 0}}>
              Nnoweb3 provides a leading blockchain development platform, providing secure and reliable blockchain encryption applications to over 5 million users worldwide
            </AdantageGroupContent>
          </AdvantagesGroup>
        </AdvantagesContent>
      </AdvantagesBox>
    </AdvantagesStyle>
  )
}
