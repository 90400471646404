import {createGlobalStyle} from "styled-components";
import {MEDIA_WIDTHS} from "./theme";

/// 内容宽度
export const CONTENT_WIDTH = MEDIA_WIDTHS.xl;
export const BOX_CONTENT_WIDTH = "1200px";
/* 全局样式 */
export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    line-height: normal;
    font-family: 'Gen', sans-serif;
  }
  @keyframes animation-1o9v6px {
      from {
          margin-left: -20%;
      }

      to {
          margin-left: 30%;
      }
  }
`

export const Z_INDEX = {
  NAVBAR: 10,
}

/* AOS 动画延时 */
export const AOS_DELAY = 60
