import React, {useEffect} from 'react';
import styled from "styled-components";
import Navbar, {NavBarHeight} from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import {Z_INDEX} from "./styles";
import ChainDevelopment from "./pages/ChainDevelopment";
import Quotation from "./pages/quotation/Quotation";
import AboutUs from "./pages/about/AboutUs";
import CexExchange from "./pages/CexExchange";
import DexExchange from "./pages/DexExchange";
import DecentralizedLending from "./pages/DecentralizedLending";
import CrossChainBridge from "./pages/CrossChainBridge";
import DecentralizedTrans from "./pages/DecentralizedTrans";
import NFTTradingSystem from "./pages/NFTTradeingSystem";
import BlockchainWalletSystem from "./pages/BlockchainWalletSystem";
import BlockchainPaymentSystem from "./pages/BlockchainPaymentSystem";
import {Routes, Route, useLocation} from "react-router-dom";
import Pricing from "./pages/Pricing";
import AOS from "aos";
import "aos/dist/aos.css"


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`

const NavbarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${NavBarHeight};
  z-index: ${Z_INDEX.NAVBAR};
`

const BodyWrapper = styled.div`
  flex: 1;
`

function App() {
  const history = useLocation()

  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        // mirror: false
        once: true
      })
    }, 100)
  }, [window.location.pathname])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0;
    window.scrollTo(0, 0)
  }, [history.pathname]);

  return <Wrapper>
    <NavbarWrapper><Navbar/></NavbarWrapper>
    <BodyWrapper>
      <Routes>
        <Route path={"/"} element={<Home/>}/>
        <Route path={"/development"} element={<ChainDevelopment/>}/>
        <Route path={"/product"}>
          <Route path={"cex"} element={<CexExchange/>}/>
          <Route path={"dex"} element={<DexExchange/>}/>
          <Route path={"decentralized"} element={<DecentralizedLending/>}/>
          <Route path={"cross-chain"} element={<CrossChainBridge/>}/>
          <Route path={"transaction-mining"} element={<DecentralizedTrans/>}/>
          <Route path={"nft-trading"} element={<NFTTradingSystem/>}/>
          <Route path={"blockchain-wallet"} element={<BlockchainWalletSystem/>}/>
          <Route path={"payment"} element={<BlockchainPaymentSystem/>}/>
        </Route>
        <Route path={"/quotation"} element={<Quotation/>} />
        <Route path={"/about"} element={<AboutUs/>} />
        {/*<Route path={"/pricing"} element={<Pricing/>}/>*/}
      </Routes>
    </BodyWrapper>
    <Footer/>
  </Wrapper>

}

export default App;
