import styled from "styled-components";
import React from "react";
import {layout, LayoutProps} from "styled-system";

export interface ButtonProps extends LayoutProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const StyledButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  ${layout};
`

const Default = styled(StyledButton)`
  background: #6C55FA;
  color: #fff;
  transition: all .2s;

  &:hover {
    // background: #4A3CBD;
  }
  
  &:disabled {
    background: #6C55FA;
  }
`

export function ButtonDefault({...rest}: ButtonProps) {
  return <Default {...rest}/>
}
