import Box from "../../components/Box";
import BG from "../../assets/svg/home/home_banner_bg.svg"
import BG2 from "../../assets/svg/home/img_home_bg_3.svg"
import BannerBG from "../../assets/svg/home/home_banner_logo.svg"
import styled from "styled-components";
import BGIcon from "../../assets/svg/home/img_home_bg_2.svg"
import Column from "../../components/Column";
import {CONTENT_WIDTH} from "../../styles";
import {ButtonDefault} from "../../components/Button";
import {MEDIA_WIDTHS} from "../../theme";
import {FadeUp} from "../../styles/aos";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom"
import bgImg from 'src/assets/images/banner.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #0F2B6A url(${bgImg}) no-repeat center / cover;
  padding: 120px 32px;
  min-height: 100vh;

  // ${({theme}) => theme.mediaQueries.xl} {
  //   background: url(${BG}) no-repeat center / cover;
  // }

  ${({theme}) => theme.mediaQueries.md} {
    padding: 120px 24px 64px;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    padding: 120px 16px 64px;
  }
`

const WrapperContent = styled.div`
  align-self: center;
  max-width: ${MEDIA_WIDTHS.xl}px;
  width: 100%;
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: center;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`

const TextBox = styled(Column)`
  max-width: 980px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;  
  ${({theme}) => theme.mediaQueries.md} {
    text-align: center;
  }

  overflow: hidden;
`

const Title = styled(Box)`
  font-size: 64px;
  text-transform: uppercase;

  ${({theme}) => theme.mediaQueries.sm} {
    font-size: 48px;
  }
`

const SubTitle = styled(Box)`
  font-size: 60px;
  line-height: 1.2em;

  ${({theme}) => theme.mediaQueries.sm} {
    font-size: 32px;
  }
`

export const ConsultNowButton = styled(ButtonDefault)`
    width: 320px;
    height: 84px;
    background: linear-gradient(270deg, #37FD6B 8%, #63FF8A 100%);
    font-size: 38px;
    font-weight: 700;
    color: #333;
    &:hover{
        background: linear-gradient(270deg, #37FD6B 8%, #63FF8A 100%);
    }
    ${({ theme }) => theme.mediaQueries.md} {
        width: 100%;
    }
`

export const Cover = styled.img`
  //display: none;
  //visibility: hidden;
  max-width: 382px;
  width: 100%;


  ${({theme}) => theme.mediaQueries.xl} {
    visibility: visible;
  }

  ${({theme}) => theme.mediaQueries.sm} {
    visibility: visible;
    max-width: 242px;
  }
`

export default function Banner() {
  const nav = useNavigate()

  return <Wrapper>
    <WrapperContent {...FadeUp}>
      {/*<Cover src={BannerBG}/>*/}
      <TextBox>
        {/*<Title*/}
        {/*  color={`#6C55FA`}*/}
        {/*  fontWeight={700}*/}
        {/*>Blockchain</Title>*/}
        <SubTitle
          color={`#fff`}
          fontWeight={700}
          marginTop={`12px`}
        >Blockchain high-end one-stop<br/>Service Platform</SubTitle>
        <Box
          color={`#fff`}
          fontSize={`30px`}
          fontWeight={500}
          lineHeight={`1.5em`}
          margin={`48px auto 0`}
          width={"758px"}
        >Innoweb3 is a leading Web3 infrastructure development company, dedicated to providing one-stop services and industry solutions for the blockchain industry</Box>
        <Box marginTop={`100px`}>
          <ConsultNowButton onClick={() => {
            nav("/quotation")
          }}>Contact US</ConsultNowButton>
        </Box>
      </TextBox>

    </WrapperContent>
  </Wrapper>
}
