import React, { CSSProperties, HTMLProps, ReactNode } from 'react';
import { SvgIconStyle } from './SvgIcon.style';
import Toggle from "../toggle/Toggle";

type IProps = {
    children?: ReactNode
    style?: CSSProperties
    color?: string
    strokeColor?: string
    dangerouslySetInnerHTML?: string
    className?: string
    width?: string
    height?: string
}
export default function SvgIcon(props: IProps & Omit<HTMLProps<HTMLSpanElement>, "dangerouslySetInnerHTML">) {
    const {dangerouslySetInnerHTML, ...rest} = props;

    return (
        <Toggle vIf={!!props.dangerouslySetInnerHTML}>
            <SvgIconStyle
                className={props.className}
                style={props.style}
                width={props.width}
                height={props.height}
                color={props.color}
                strokeColor={props.strokeColor}
                onClick={props.onClick}
                dangerouslySetInnerHTML={{__html: props.dangerouslySetInnerHTML || ""}} />
            <SvgIconStyle
                className={props.className}
                style={props.style}
                width={props.width}
                height={props.height}
                onClick={props.onClick}
                color={props.color}
                strokeColor={props.strokeColor}>
                {props.children}
            </SvgIconStyle>
        </Toggle>

    )
}
