import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import decorate2 from "../../assets/images/decorate2.png";
import decorate3 from "../../assets/images/decorate3.png";
import bgIcon1 from "../../assets/images/trading/bgIcon1.png";
import Box from "../../components/Box";
import Flex from "../../components/Box/Flex";

const contentWidth = "888px";
export const BannerContent = styled.div`
    position: relative;
    width: ${contentWidth};
    height: 402px;
    margin: 0 auto 0;
    color: #fff;
    text-align: center;
    background: 
            url(${bgIcon1}) 47px 0px / 190px 190px no-repeat,
            url(${decorate2}) 766px 14px / 80px 80px no-repeat,
            url(${decorate2}) 155px 284px / 118px 118px no-repeat,
            url(${decorate3}) 750px 300px / 36px 36px no-repeat;
`;
export const BannerTitle = styled.h3`
    font-size: 50px;
    font-weight: 900;
    line-height: 80px;
    margin: 0 0 10px;
    color: #00032E;
    padding-top: 34px;
`;
export const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 500;
    color: #3D3D3D;
    padding-left: 28px;
`;
export const BannerButton = styled.button`
    width: 144px;
    height: 58px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 700;
    margin-top: 56px;
    margin-left: 28px;
    cursor: pointer;
`;

export const AdvantageBox = styled(Box)`
    width: ${contentWidth};
    margin: 80px auto 0;
    color: #3D3D3D;
`;
export const AdvantageTitle = styled.h3`
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 32px;
`;
export const AdvantageContent = styled.div`
    display: grid;
    grid-template-columns: 436px 1fr;
    grid-gap: 18px 32px;
`;
export const HotBox = styled.div`
    padding: 38px 42px;
    border-radius: 12px;
    background: #EAF7EE;
    grid-row-start: 1;
    grid-row-end: 4;
`;
export const AdvantageItem = styled.div`
    min-height: 106px;
    border-radius: 12px;
    background: #EAF7EE;
    padding: 24px 18px 24px 34px;
`;
export const AdvantageItemTitle = styled.h3`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
`;
export const AdvantageItemDesc = styled.h3`
    font-size: 14px;
    font-weight: 400;
`;

export const CaseContainer = styled.div`
    width: ${contentWidth};
    margin: 116px auto 130px;
`;
export const CaseBox = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    color: #3D3D3D;
`;
export const CaseTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 10px;
    color: #3D3D3D;
`;
export const CaseDesc = styled.div`
    
`;
export const CaseRightBox = styled.div`
    width: 350px;
`;
export const OptionItem = styled.div`
    position: relative;
    padding-left: 14px;
    &:before{
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #3CCE63;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`;
