import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import Flex from "../../components/Box/Flex";
import AvatarImg from 'src/assets/images/Avatar.png';
import aboutBg from 'src/assets/images/aboutBg.png';
import Text from 'src/components/Text/index';
import Advantages from "../Home/Advantages";
import { FadeUp } from "../../styles/aos";

const AboutUsStyle = styled.div`
    min-height: 100vh;
    background: #fff;
    color: #3D3D3D;
    padding-top: 150px;
    box-sizing: border-box;
    margin-bottom: 36px;
`;
const Banner = styled.div`
    width: 740px;
    margin: 120px auto;
    text-align: center;
`;
const Title = styled.h3`
    font-size: 34px;
    font-weight: 900;
`;
const Desc = styled(Text)`
    font-size: 20px;
    font-weight: 400;
`;

const TeamBox = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 100px;
    position: relative;
`;
const TeamItem = styled.div`
    
`;

const TabBox = styled.div`
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    padding: 0 24px;
`;
const TabItem = styled.div<{active?: boolean}>`
    padding: 28px 22px;
    font-size: 20px;
    font-weight: 500;
    background: #F1F1F1;
    color: #161729;
    position: relative;
    border-bottom: 4px solid transparent;
    background-clip: padding-box;
    cursor: pointer;
    ${({active}) => 
            active ? 
                    css`
                        background: #161729;
                        color: #fff;
                        border-bottom-color: #161729;
                        &:before{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 50%;
                            height: 14px;
                            width: 0;
                            border-top: 6px solid transparent;
                            border-right: 255px solid #2AD557;
                            z-index: 1;
                            transform: translate(-50%, -100%);
                        }
                    ` 
                    : null}
`;
const Avatar = styled.img`
    width: 42px;
    height: 42px;
    margin-right: 16px;
`;
const Intro = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    padding-left: 58px;
`;

const TeamDetail = styled.div`
    position: relative;
    top: -4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    height: 594px;
    padding: 42px 24px 48px;
    border: 4px solid #2AD557;
    background: #161729;
`;
const TeamDetailItem = styled.ul<{active?: boolean}>`
    padding: 22px 20px;
    background: #fff;
    color: #3D3D3D;
    opacity: ${({active}) => active ? 1 : 0.6};
    list-style: none;
    box-sizing: border-box;
    word-break: break-word;
`;
const TeamDetailInfo = styled.li`
    position: relative;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #37F068;
    }
    &:not(:last-child){
    }
`;

const MissionStyle = styled.div`
    margin-top: 92px;
`;
const MissionTitle = styled.h3`
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    margin: 0;
`;
const MissionBox = styled.div`
    width: 658px;
    height: 658px;
    position: relative;
    margin: auto;
    background: url(${aboutBg});
    background-size: 100% 100%;
`;
const MissionGroup = styled.div`
    position: absolute;
    padding-left: 36px;
`;
const MissionGroupTag = styled.div`
    width: 25px;
    height: 25px;
    border: 6px solid #fff;
    border-radius: 50%;
    background: linear-gradient(180deg, #37F068 0%, #17D84A 100%);
    box-sizing: border-box;
    position: absolute;
`;
const MissionGroupTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
`;
const MissionGroupInfo = styled.div`
    font-size: 12px;
`;

export default function AboutUs() {
  const [active, setActive] = useState(0);

  const teamArr = [
    {
      name: "Eric Yang",
      post: "Founder",
      intro: [
        "Xidian Communication, military Cryptography, early practitioners of blockchain, participating in XMC/XMR bifurcation",
        "Vice Chairman of CCID Blockchain Industry Alliance",
        "Vice President of Xi'an Block Chain Technology Association",
        "Previously served as CEO/CTO of a well-known blockchain company",
        "SF is responsible for core technology projects",
        "Director of CSST and Peking University Collaborating Laboratory",
        "Participate in the development and review of national AVS multimedia national standards",
        "Leading mobile applications for over ten million users"
      ]
    },
    {
      name: "Jiang Zhang",
      post: "Distributed Storage Expert",
      intro: [
        "Postdoctoral fellow of Peking University Institute of Information Science",
        "Expert in audio and video encoding and decoding algorithms",
        "Distributed Storage Research Expert",
        "Participated in the development of multiple national standards",
        "Participated in well-known technical forums and held positions",
        "Multiple national patents and international conference papers",
        "Participate in the core work of Pengcheng Experiment",
        "In-depth research on ipfs distributed storage technology"
      ]
    },
    {
      name: "Tao Zhang",
      post: "Bottom Chain Expert",
      intro: [
        "Technical managers of multiple well-known technology companies",
        "Over 7 years of development in public and alliance chains",
        "Bitcoin/Fabric/ETH/Coda/Cosmos/Polygon Research and Practice",
        "Leading over 35 underlying chain projects",
        "Participated in the core development of well-known public chains in multiple industries such as XMR/VNS, and is a language expert.",
        "Leading multiple government and large enterprise high concurrency and high availability projects"
      ]
    },
    {
      name: "Bruce Liu",
      post: "Technical expert",
      intro: [
        "Renowned 211 University Institute of Information Technology, blockchain continuous entrepreneur",
        "Participated in the development of Poka ecological technology, with over 7 years of technical development experience",
        "Participated in multiple encryption and zk project development",
        "Previously worked at renowned bat and participated in core development of multiple projects",
        "Participated in leading the construction of platforms with over a million users"
      ]
    }
  ]

  return (
    <AboutUsStyle>
      <Banner  {...FadeUp}>
        <Title>About Innoweb</Title>
        <Desc>Headquartered in Singapore, we are committed to providing enterprises with a complete set of blockchain technology solutions, covering infrastructure development and Web3 ecosystem support</Desc>
        <Desc mt={"10px"}>Provide a leading blockchain development platform that supports millions of users in 197 countries worldwide</Desc>
      </Banner>
      <TeamBox {...FadeUp}>
        <TabBox>
          {
            teamArr.map((item, index) => {
              return <TabItem key={index} active={index === active} onClick={() => setActive(index)}>
                <Flex alignItems={"center"}>
                  <Avatar src={AvatarImg} />
                  <Text>{item.name}</Text>
                </Flex>
                <Intro>{item.post}</Intro>
              </TabItem>
            })
          }
        </TabBox>
        <TeamDetail>
          {
            teamArr.map((item, index) => {
              return <TeamDetailItem key={index} active={index === active}>
                {
                  item.intro.map((item2, index2) => {
                    return <TeamDetailInfo key={index2}>{item2}</TeamDetailInfo>
                  })
                }
              </TeamDetailItem>
            })
          }
        </TeamDetail>
      </TeamBox>
      <Advantages />
      <MissionStyle {...FadeUp}>
        <MissionTitle>Mission Vision</MissionTitle>
        <MissionBox>
          <MissionGroup style={{width: "360px", top: "322px", left: "-180px", textAlign: "right", paddingLeft: "0", paddingRight: "36px"}}>
            <MissionGroupTag style={{right: 0, top: "4px"}} />
            <MissionGroupTitle>Original mission intention</MissionGroupTitle>
            <MissionGroupInfo style={{paddingLeft: "6%"}}>Our mission is to provide developers with the basic building blocks needed to create the future of technology, helping companies benefit from blockchain technology</MissionGroupInfo>
          </MissionGroup>
          <MissionGroup style={{width: "290px", top: "110px", left: "436px"}}>
            <MissionGroupTag style={{left: 0, top: "4px"}} />
            <MissionGroupTitle>Global Vision</MissionGroupTitle>
            <MissionGroupInfo>Build developer friendly products and intuitive interfaces for end users. The core of everything lies in simplicity, top-level user experience, and top-level security</MissionGroupInfo>
          </MissionGroup>
          <MissionGroup style={{width: "308px", top: "510px", left: "446px"}}>
            <MissionGroupTag style={{left: 0, top: "4px"}} />
            <MissionGroupTitle>Core values</MissionGroupTitle>
            <MissionGroupInfo>Driven by innovation in encryption technology, we aim to create an open source community of over a million developers and strive to promote the birth of IBM in the blockchain field</MissionGroupInfo>
          </MissionGroup>
        </MissionBox>
      </MissionStyle>
    </AboutUsStyle>
  )
}
