import React from "react";
import Box from "../../components/Box";
import bannerBg from 'src/assets/images/decentralized/bannerBg.png';
import decorateImg from 'src/assets/images/decentralized/decorate.png';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import decorateImg2 from "../../assets/images/decorate.png";
import goldIcon from 'src/assets/images/decentralized/gold.svg';
import leverIcon from 'src/assets/images/decentralized/lever.svg';
import editLeverIcon from 'src/assets/images/decentralized/editLever.svg';
import contractIcon from 'src/assets/images/decentralized/contract.svg';
import Flex from "../../components/Box/Flex";
import SplitLine from "./SplitLine";
import textTag from "../../assets/svg/textTag.svg"
import DecorateIcon3 from 'src/components/decorateIcon/DecorateIcon';
import { FadeUp } from "../../styles/aos";

const HeaderContainer = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto;
`;
const HeaderTitle = styled.div`
    width: 500px;
    font-size: 34px;
    font-weight: 900;
    color: #00032E;
`;
const Banner = styled.div`
    height: 502px;
    background: url(${bannerBg});
    background-size: cover;
    position: relative;
    margin-top: 38px;
`;
const BannerMoiddle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto;
    padding-top: 45px;
`;
const BannerContent = styled.div`
    position: relative;
    width: 728px;
    height: 312px;
    margin: 46px 0 0;
    background: #fff;
    color: #3D3D3D;
    padding: 32px 42px;
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
`;
const BannerButton = styled.button`
    width: 144px;
    height: 58px;
    position: absolute;
    left: 42px;
    bottom: 0;
    transform: translateY(50%);
    border-radius: 2px;
    opacity: 1;
    background: #15A83C;
    border: none;
    outline: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
`;
const DecorateIcon = styled.img`
    position: absolute;
`;

const IntroductionBox = styled(Box)`
    width: ${BOX_CONTENT_WIDTH};
    margin: 92px auto;
`;
const Title = styled.div`
    position: relative;
    font-size: 40px;
    font-weight: 900;
    color: #3D3D3D;
    margin-bottom: 70px;
    width: 392px;
`;
const DecorateIcon2 = styled.div`
    width: 164px;
    height: 164px;
    background: url(${decorateImg2});
    background-size: 100% 100%;
    position: absolute;
`;

const IntroBox = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
`;
const IntroGroup = styled(Flex)`
    justify-content: space-between;
    padding: 0 0 0 32px;
    &:first-child{
        padding: 0;
    }
    &:last-child{
        
    }
`;
const IntroContent = styled.div`
    
`;
const IntroIconBox = styled(Flex)`
    width: 54px;
    height: 54px;
    align-items: center;
    margin-bottom: 32px;
`;
const IntroTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 20px;
`;
const IntroTitleItem = styled.span`
    color: #3D3D3D;
    &:not(:last-child){
        color: #27E85A;
        margin-right: 4px;
    }
`;
const IntroDesc = styled.div`
    font-size: 16px;
    font-weight: 400;
    padding-right: 32px;
`;
const SplitBox = styled.div`
    margin-top: 8px;
`;

const MiddleBox = styled.div`
    background: #191933;
    margin-bottom: 112px;
`;
const MiddleContent = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    height: 250px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 40px;
    font-weight: 900;
    color: #fff;
`;

const ProductBox = styled.div`
    width: 1264px;
    margin: 0 auto 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px 92px;
`;

const ProductGroup = styled.div`
    position: relative;
    &:before{
        content: "";
        width: 90%;
        height: 60px;
        background: #2AD557;
        border-radius: 6px;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-50%) rotate(1deg);
    }
`;
const ProductItem = styled.div`
    position: relative;
    height: 250px;
    border-radius: 6px;
    opacity: 1;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    padding: 32px 24px;
    color: #3D3D3D;
    z-index: 0;
`;
const ProductTitle = styled.h3`
    font-size: 20px;
    font-weight: 700;
    position: relative;
    padding-left: 22px;
    margin: 0 0 12px;
    &:before{
        content: "";
        width: 16px;
        height: 16px;
        border: 1px solid #27E85A;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`;
const ProductDesc = styled.div`
    padding-left: 22px;
    font-size: 14px;
`;


export default function DexExchange() {

  const IntroArr = [
    {
      icon: goldIcon,
      title: ["Currency Trading", "System"],
      desc: "Spot trading can achieve market and limit trading, as well as stop loss functions. The maximum sliding point is 0.5%, beyond which transactions cannot be made. Similar to the margin trading interface, it can display the K-line and trading position of a centralized exchange."
    },
    {
      icon: contractIcon,
      title: ["Contract Trading", "System"],
      desc: "Mature functionality, supporting bidirectional warehouse opening and warehouse by warehouse management. There is a comprehensive risk prevention system and delivery can be made at index prices, maximizing the protection of users' rights and asset security. A powerful memory matching engine ensures high concurrency transactions and supports API ordering."
    },
    {
      icon: leverIcon,
      title: ["Loan", "System"],
      desc: `Borrowing uses the lending pool mode, which is a "peer-to-peer pool" mode, where both lenders and borrowers interact with the lending pool. Each asset has a lending pool, all managed by smart contracts.`
    },
    {
      icon: editLeverIcon,
      title: ["Leveraged trading", "System"],
      desc: "A trader borrows an asset and then trades (buy/sell) it into another asset, starting to accrue interest, which is called opening a position. When closing a position, the position is sold and the borrowed assets are returned to the lender (creditor) along with interest. Short selling (leveraged short selling) and leveraged long selling are both processes mentioned above."
    }
  ];

  const ProductArr = [
    {
      title: "Security and privacy",
      desc: "Decentralized and completely transparent decentralized perpetual contract transactions, all funds are managed by smart contracts, and the platform cannot operate any funds from users."
    },
    {
      title: "lightning speed",
      desc: "Transactions can be executed immediately and confirmed on the blockchain within hours"
    },
    {
      title: "Quick withdrawal",
      desc: "Unlike other platforms, there is no need to wait to withdraw funds from Layer2"
    },
    {
      title: "Smart contracts",
      desc: "All smart contract codes are open source and have been audited and verified through professional institutions for attacks. Through smart contracts, users can recharge and withdraw their funds at any time."
    },
    {
      title: "Rapid deployment",
      desc: "The system adopts a distributed architecture and is quickly and easily deployed to achieve rapid online testing of K-line, market, and deep data"
    },
    {
      title: "Ultra low cost",
      desc: "After depositing in Layer2, you no longer need to pay fees for each transaction."
    }
  ];

  return <Box background={`#fff`} pt={"168px"}>
    <HeaderContainer {...FadeUp}>
      <HeaderTitle>Development of Decentralized exchanges</HeaderTitle>
    </HeaderContainer>
    <Banner {...FadeUp}>
      <BannerMoiddle>
        <BannerContent>
          <div> A completely transparent decentralized and decentralized perpetual contract transaction that is not managed and decentralized. All funds are managed by smart contracts, and the platform cannot operate any funds of users. All smart contract codes are open source and verified through professional auditing and attacks. Through smart contracts, users can recharge and withdraw their funds at any time.</div>
          <BannerButton onClick={() => window.open("https://dxq.xsdx.xyz/", "_blank")}>TRAIL</BannerButton>
          <DecorateIcon src={decorateImg} style={{right: "-32px", bottom: "8px"}} />
          <DecorateIcon src={decorateImg} style={{right: "32px", bottom: "-28px"}} />
        </BannerContent>
      </BannerMoiddle>
    </Banner>
    <IntroductionBox {...FadeUp}>
      <Title>
        <DecorateIcon2 style={{left: "-44px", top: "50%", transform: "translate(0, -50%) rotate(70deg)"}} />
        <span style={{position: "relative"}}>Product Function Introduction</span>
      </Title>
      <IntroBox>
        {
          IntroArr.map((item, index) => {
            return <IntroGroup key={index}>
              <IntroContent style={{marginRight: index !== IntroArr.length - 1 ? "32px" : 0}}>
                <IntroIconBox>
                  <img src={item.icon} alt=""/>
                </IntroIconBox>
                <IntroTitle>
                  {
                    item.title.map((item2, index2) => {
                      return <IntroTitleItem key={index2}>{item2}</IntroTitleItem>
                    })
                  }
                </IntroTitle>
                <IntroDesc>{item.desc}</IntroDesc>
              </IntroContent>
              {
                index !== IntroArr.length - 1
                  ? <SplitBox>
                    <SplitLine style={{marginBottom: "32px"}} />
                    <SplitLine />
                  </SplitBox>
                  : null
              }
            </IntroGroup>
          })
        }
      </IntroBox>
    </IntroductionBox>
    <MiddleBox {...FadeUp}>
      <MiddleContent>
        <DecorateIcon3 style={{left: "-20px", top: "50%", transform: "translate(0, -50%)"}} />
        <DecorateIcon2 style={{right: "0", bottom: "8px", transform: "rotate(70deg)"}} />
        <div style={{position: "relative"}}>
          <Flex alignItems={"flex-end"}>
            <div>Function</div>
            <img src={textTag} style={{marginLeft: "16px", marginBottom: "10px"}} alt=""/>
          </Flex>
          <div>Characteristics</div>
        </div>
      </MiddleContent>
    </MiddleBox>
    <ProductBox {...FadeUp}>
      {
        ProductArr.map((item, index) => {
          return <ProductGroup key={index}>
            <ProductItem>
              <ProductTitle>{item.title}</ProductTitle>
              <ProductDesc>{item.desc}</ProductDesc>
            </ProductItem>
          </ProductGroup>
        })
      }
    </ProductBox>
  </Box>
}
