import DecentralizedLending from "./index";
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import bannerBg from 'src/assets/images/lending/bannerBg.png';
import full from 'src/assets/images/lending/full.png';
import contentBg from 'src/assets/images/lending/contentBg.png';

export const LendingStyle = styled.div`
    padding-top: 140px;
`;
export const BannerContent = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 0;
    color: #fff;
    padding-bottom: 96px;
    background: url(${bannerBg}) no-repeat;
    background-size: 472px 234px;
    background-position: right bottom;
`;
export const BannerTitle = styled.h3`
    font-size: 34px;
    font-weight: 900;
    margin: 0 0 24px;
    color: #3D3D3D;
`;
export const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 400;
    width: 596px;
    color: #3D3D3D;
`;
export const BannerButton = styled.button`
    width: 186px;
    height: 66px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 700;
    margin-top: 56px;
    cursor: pointer;
`;

export const IntroHeader = styled.div`
    background: url(${full});
    background-size: cover;
`;
export const IntroHeaderContent = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    height: 306px;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;
export const IntroHeaderTitle = styled.h3`
    font-size: 40px;
    font-weight: 900;
    color: #3D3D3D;
    width: 392px;
    margin: 0;
`;

export const IntroContentBox = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    height: 980px;
    margin: auto;
    position: relative;
    background: url(${contentBg}) center no-repeat;
    background-size: 338px 364px;
`;
export const SplitLineStyle = styled.div`
    position: absolute;
    width: 1px;
    height: 208px;
    background: #D6D6D6;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 10px;
        height: 10px;
        border: 1px solid #D6D6D6;
        border-radius: 50%;
    }
`;

export const IntroItem = styled.div`
    position: absolute;
`;
export const IntroItemTitle = styled.h3`
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
`;

export const IntroItemContent = styled.div`
    width: 292px;
    padding-left: 22px;
    font-size: 14px;
    box-sizing: border-box;
`;
