import React, { CSSProperties } from 'react';
import styled from "styled-components";

const SplitLineStyle = styled.div`
    position: relative;
    width: 1px;
    height: 212px;
    background: #D6D6D6;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 10px;
        height: 10px;
        border: 1px solid #D6D6D6;
        border-radius: 50%;
    }
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 10px;
        height: 10px;
        border: 1px solid #D6D6D6;
        border-radius: 50%;
    }
`;

type IProps = {
  style?: CSSProperties
}
export default function SplitLine(props: IProps) {

  return (
    <SplitLineStyle style={props.style}>

    </SplitLineStyle>
  )
}
