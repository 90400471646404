import React, { useState } from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import Flex from "../../components/Box/Flex";
import icon from 'src/assets/images/quotation/icon.svg';
import Grid from "../../components/Box/Grid";
import decorateImg from "../../assets/images/decorate.png";
import { SubmitHandler } from "react-hook-form";
import axios from "axios";
import { FadeUp } from "../../styles/aos";

const QuotationStyle = styled.div`
    min-height: 100vh;
    background: #1C1929;
    padding-top: 150px;
    box-sizing: border-box;
`;

const QuotationContent = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto;
    padding-bottom: 28px;
`;

const BannerBox = styled.div`
    position: relative;
`;

const BannerImgBox = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;
const BannerImgModalLeft = styled.div`
    pointer-events: none;
    background: linear-gradient(to right, rgb(28, 25, 41) 0%, rgb(28, 25, 41) 50%, rgba(28, 25, 41, 0.75) 75%, transparent 100%);
    position: absolute;
    z-index: 1;
    width: 35%;
    height: 100%;
    left: 0px;
`;
const BannerImgModalRight = styled.div`
    pointer-events: none;
    background: linear-gradient(to left, rgb(28, 25, 41), transparent 100%);
    position: absolute;
    z-index: 1;
    width: 240px;
    right: 0px;
    height: 100%;
`;
const BannerImgRow = styled(Flex)`
    animation: animation-1o9v6px infinite 13s linear alternate;
`;
const BannerImg = styled.img`
    width: 195px;
    height: 110px;
    &:not(:last-child){
        margin-right: 12px;
    }
`;

const TitleBox = styled(Flex)`
    position: relative;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    z-index: 2;
`;

const Dsc = styled.div`
    position: relative;
    width: 446px;
    height: 194px;
    font-size: 28px;
    font-weight: 400;
    color: #fff;
    z-index: 2;
`;

const FormContainer = styled(Flex)`
    position: relative;
    justify-content: space-between;
    width: 1043px;
    padding: 58px 66px 28px 88px;
    background: linear-gradient(180deg, #17182A 0%, #010114 100%);
    border-radius: 12px;
    margin-top: 100px;
`;

const FormItem = styled.div`

`;
const FormLabel = styled.div`
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 12px;
`;
const InputStyle = styled.input`
    width: 100%;
    height: 26px;
    border: 1px solid #2F2F57;
    border-radius: 4px;
    background: transparent;
    outline: none;
    padding-left: 8px;
    font-size: 12px;
    grid-template-columns: repeat(2, 1fr);
    font-weight: 400;
    color: #fff;
`;
const FormTextarea = styled.textarea`
    width: 100%;
    height: 122px;
    border: 1px solid #2F2F57;
    border-radius: 4px;
    background: transparent;
    outline: none;
    padding: 8px;
    font-size: 12px;
    grid-template-columns: repeat(2, 1fr);
    font-weight: 400;
    color: #fff;
    resize: none;
`;

const SubmitButton = styled.button`
    width: 159px;
    height: 36px;
    border-radius: 4px;
    opacity: 1;
    background: linear-gradient(270deg, #37FD6B 8%, #63FF8A 100%);
    box-shadow: 0px 8px 10px 0px rgba(41, 65, 255, 0.2);
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #3D3D3D;
    margin-top: 30px;
    cursor: pointer;
`;

const DecorateIcon = styled.div`
    width: 104px;
    height: 104px;
    background: url(${decorateImg});
    background-size: 100% 100%;
    position: absolute;
`;

export default function Quotation() {
  const [name, setName] = useState("");
  const [contract, setContract] = useState("");
  const [content, setContent] = useState("");
  const [weChat, setWeChat] = useState("");
  const [email, setEmail] = useState("");

  async function onSubmit() {
    if (!name) {
      return;
    }
    try {
      let resp = await axios.post("/api/officialConsult/contact", {
        name,
        phone: contract,
        weChat: weChat,
        email: email,
        content
      })
      if (resp.status === 200) {
        alert("Submit success")
        setName("");
        setContract("");
        setContent("");
        setWeChat("");
        setEmail("");
      }
    } catch (e) {

    }
  }

  return (
    <QuotationStyle>
      <QuotationContent>
        <BannerBox {...FadeUp}>
          <TitleBox>
            <span style={{color: "#2AD557"}}>Innoweb3&nbsp;</span>
            <span>Quote Service</span>
          </TitleBox>
          <Dsc>We provide a pricing mechanism for multiple product combinations and multiple solution choices</Dsc>
          <BannerImgBox>
            <BannerImgModalLeft />
            <BannerImgModalRight />
            <BannerImgRow>
              <BannerImg src={require("src/assets/images/quotation/quotation1.png")} />
              <BannerImg src={require("src/assets/images/quotation/quotation2.png")} />
              <BannerImg src={require("src/assets/images/quotation/quotation3.png")} />
            </BannerImgRow>
            <BannerImgRow style={{padding: "24px 0 0 92px"}}>
              <BannerImg src={require("src/assets/images/quotation/quotation4.png")} />
              <BannerImg src={require("src/assets/images/quotation/quotation5.png")} />
              <BannerImg src={require("src/assets/images/quotation/quotation6.png")} />
            </BannerImgRow>
          </BannerImgBox>
        </BannerBox>
        {/*translate(0, -50%) rotate(70deg)*/}
        <div style={{position: "relative", width: "max-content", margin: "0"}} {...FadeUp}>
          <DecorateIcon style={{left: "-42px", top: "-70px", transform: "rotate(70deg)"}} />
          <DecorateIcon style={{width: "58px", height: "58px", top: "30px", right: "170px", transform: "rotate(70deg)", zIndex: 1}} />
          <DecorateIcon style={{width: "52px", height: "52px", bottom: "8px", right: "25px", transform: "rotate(-160deg)", zIndex: 1}} />
          <FormContainer>
            <img src={require("src/assets/images/quotation/image.png")} style={{width: "317px", height: "427px"}} alt=""/>
            <div>
              <Flex mb={"16px"}>
                <img src={icon} style={{width: "28px", marginRight: "8px"}} alt=""/>
                <span style={{fontSize: "24px", color: "#fff"}}>Get a quote</span>
              </Flex>
              <Grid style={{width: "468px"}} gridTemplateColumns={"repeat(2, 1fr)"} gridColumnGap={"52px"} gridRowGap={"22px"}>
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <InputStyle placeholder={"Please enter"} value={name} onChange={(event) => setName(event.target.value)} />
                </FormItem>
                <FormItem>
                  <FormLabel>WeChat</FormLabel>
                  <InputStyle placeholder={"Please enter"} value={weChat} onChange={(event) => setWeChat(event.target.value)} />
                </FormItem>
                <FormItem>
                  <FormLabel>Phone number</FormLabel>
                  <InputStyle placeholder={"Please enter"} value={contract} onChange={(event) => setContract(event.target.value)}  />
                </FormItem>
                <FormItem>
                  <FormLabel>Email number</FormLabel>
                  <InputStyle placeholder={"Please enter"} value={email} onChange={(event) => setEmail(event.target.value)} />
                </FormItem>
                <FormItem style={{gridColumnStart: "span 2"}}>
                  <FormLabel>What to consult about</FormLabel>
                  <FormTextarea placeholder={"Please enter"} value={content} onChange={(event) => setContent(event.target.value)}  />
                </FormItem>
              </Grid>
              <SubmitButton onClick={onSubmit}>Submit</SubmitButton>
            </div>
          </FormContainer>
        </div>
      </QuotationContent>
    </QuotationStyle>
  )
}
