import React from 'react';
import ReactDOM from 'react-dom/client';
// import "normalize.css/normalize.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GlobalStyle} from "./styles";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import theme from "./theme";
import "swiper/css/bundle"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <App/>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
