import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import bannerBg from "../../assets/images/trading/bannerBg.png";
import bgIcon1 from "../../assets/images/trading/bgIcon1.png";
import bgIcon2 from "../../assets/images/trading/bgIcon2.png";
import Box from "../../components/Box";
import decorateImg2 from "../../assets/images/decorate.png";

export const BannerContent = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 0;
    color: #fff;
    padding-bottom: 96px;
    background: 
            url(${bannerBg}) right 40px / 410px 314px no-repeat,
            url(${bgIcon1}) 365px 0px / 190px 190px no-repeat,
            url(${bgIcon1}) 702px 475px / 65px 65px no-repeat,
            url(${bgIcon2}) right bottom / 228px 114px no-repeat;
    border-bottom: 7px solid #F6F6F6;
`;
export const BannerTitle = styled.h3`
    width: 592px;
    font-size: 80px;
    font-weight: 900;
    line-height: 80px;
    margin: 0 0 48px;
    color: #00032E;
`;
export const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 500;
    width: 618px;
    color: #3D3D3D;
`;
export const BannerButton = styled.button`
    width: 186px;
    height: 66px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 700;
    margin-top: 56px;
    cursor: pointer;
`;

export const IntroductionBox = styled(Box)`
    width: ${BOX_CONTENT_WIDTH};
    margin: 92px auto;
`;
export const Title = styled.div`
    position: relative;
    font-size: 40px;
    font-weight: 900;
    color: #3D3D3D;
    margin-bottom: 70px;
`;
export const DecorateIcon2 = styled.div`
    width: 102px;
    height: 102px;
    background: url(${decorateImg2});
    background-size: 100% 100%;
    position: absolute;
`;
export const IntroductionContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 36px;
`;
export const IntroductionGrop = styled.div`
    display: flex;
    align-items: flex-start;
`;
export const IntroductionTag = styled.div`
    position: relative;
    font-size: 60px;
    font-weight: 600;
    line-height: 20px;
    color: #020714;
    margin-right: 28px;
    margin-top: 10px;
    z-index: 0;
    &:after{
        content: "";
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 6px;
        background: #60FE83;
        z-index: -1;
    }
`;
export const IntroTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 20px;
    color: #3D3D3D;
`;
export const IntroDesc = styled.div`
    font-size: 14px;
    color: #3D3D3D;
`;
