import Box from "../../components/Box";
import Column from "../../components/Column";
import PSBanner from "../../views/ProjectService/PSBanner";
import CardWhiteGroup from "../../views/ProjectService/CardWhiteGroup";
import PSSection from "../../views/ProjectService/PSSection";
import CardGroup from "../../views/ProjectService/CardGroup";
import SectionFull from "../../components/Section/SectionFull";
import SwiperBox from "../../views/ProjectService/SwiperBox";
import PSProjectDisplay from "../../views/ProjectService/PSProjectDisplay";
import ConsultNow from "../../views/ConsultNow";
import {CexProjectAdvantagesData} from "./data";
import ProjectDisplay from "../NFTTradeingSystem/ProjectDisplay";
import ProjectDisplayMobile from "../NFTTradeingSystem/ProjectDisplay.mobile";
import styled from "styled-components";
import cexBanner from 'src/assets/images/product/cexBanner.png';
import adBg from 'src/assets/images/product/adBg.png';
import { BOX_CONTENT_WIDTH } from "../../styles";
import decorateImg from "../../assets/images/decorate.png";
import arrowIcon from "../../assets/images/product/arrow.svg";
import textTag from "../../assets/svg/textTag.svg"
import Flex from "../../components/Box/Flex";
import { NavBarHeight } from "../../components/Navbar";
import { FadeUp } from "../../styles/aos";

const DecorateIcon = styled.div`
    width: 164px;
    height: 164px;
    background: url(${decorateImg});
    background-size: 100% 100%;
    position: absolute;
`;

const Banner = styled.div`
    position: relative;
    height: 502px;
    background: url(${cexBanner});
    background-size: cover;
    padding-top: 140px;
`;
const BannerContent = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 0;
    color: #fff;
`;
const BannerTitle = styled.h3`
    font-size: 34px;
    font-weight: 900;
    margin: 0 0 24px;
    color: #fff;
`;
const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 400;
    width: 596px;
    color: #fff;
`;
const BannerButton = styled.button`
    width: 186px;
    height: 66px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 700;
    margin-top: 56px;
    cursor: pointer;
`;

const ContentStyle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 120px auto;
`;
const Title = styled.div`
    position: relative;
    font-size: 38px;
    font-weight: 700;
    color: #3D3D3D;
    padding-left: 38px;
    z-index: 1;
`;
const FunctionStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px 70px;
    margin-top: 70px;
    padding-left: 38px;
`;
const FunctionTitle = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin: 16px 0 24px;
`;


const CharacteristicsStyle = styled(Box)`
    position: relative;
`;
const CharacteristicsContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 48px;
    padding: 0 48px;
    position: relative;
    z-index: 1;
`;
const CharacteristicsGroup = styled.div`
    background: #00032E;
    padding: 42px 24px;
    border-radius: 6px;
`;
const CharacteristicsTitle = styled.h3`
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 24px;
    color: #fff;
`;

const AdvantagesContainer = styled.div`
    margin-top: 180px;
`;
const AdvantagesTitle = styled.div`
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 28px;
    color: #3D3D3D;
    text-align: center;
`;
const AdvantagesContent = styled.div`
    position: relative;
    height: 608px;
    background: url(${adBg});
    background-size: 100% 100%;
    padding: 130px 160px 0;
    z-index: 0;
`;
const AdvantagesGroup = styled.div`
    &:not(:last-child){
        margin-bottom: 20px;
    }
`;
const AdvantagesGroupTitle = styled.div<{active?: boolean}>`
    font-size: ${({active}) => active ? "28px" : "24px"};
    font-weight: ${({active}) => active ? 900 : 400};
    color: #020714;
`;
const AdvantagesDesc = styled.div`
    font-size: 20px;
    font-weight: 500;
    padding-left: 32px;
`;
const AdvantagesDecorate = styled.img`
    width: 160px;
    position: absolute;
`;

export default function CexExchange() {

  const functions = [
    {
      icon: require(`../../assets/svg/projectService/icon_currency2.svg`).default,
      title: `Currency trading system`,
      description: `Mature functionality and extensive application. A powerful memory matching engine ensures high concurrency transactions; A comprehensive front-end and back-end system ensures system security and stability, ensuring privacy, system security, and asset security for both the platform and users. Support policy delegation and internationalization.`
    },
    {
      icon: require(`../../assets/svg/projectService/icon_contract2.svg`).default,
      title: `Contract Trading System`,
      description: `Mature functionality, supporting bidirectional warehouse opening and warehouse by warehouse management. There is a comprehensive risk prevention system and delivery can be made at index prices, maximizing the protection of users' rights and asset security. A powerful memory matching engine ensures high concurrency transactions and supports API ordering.`
    },
    {
      icon: require(`../../assets/svg/projectService/icon_leveraged2.svg`).default,
      title: `Leveraged Trading System`,
      description: `The spot leverage trading system, bidding trading mechanism, shares the depth of trading positions with spot trading, and provides users with support for short-term loan fund turnover; There is a powerful memory matching engine to ensure high concurrency transactions, a comprehensive risk prevention mechanism, and a zero loss platform.`
    },
    {
      icon: require(`../../assets/svg/projectService/icon_second2.svg`).default,
      title: `Second contract system`,
      description: `Seconds Contract is a trading system used for virtual currency contract transactions that settles in units of minutes.`
    },
    {
      icon: require(`../../assets/svg/projectService/icon_otc2.svg`).default,
      title: `OTC trading system`,
      description: `3 merchant authentication modes, supporting online coin printing and offline payment in legal currency, facilitating the entry and exit of funds. Strict KYC authentication ensures secure transactions and cash flows between users`
    },
    {
      icon: require(`../../assets/svg/projectService/icon_documentary2.svg`).default,
      title: `Tracking system`,
      description: `Tracking refers to the user's ability to automatically copy and follow selected traders for placing orders, closing positions, and other operations. When a trader's order generates a profit closing position, the user's order will also be synchronously closed and locked for profit.`
    }
  ];

  const Characteristics = [
    {
      title: `Professional Trading`,
      description: `Provide two types of K-line, customized and third-party, technical analysis and marking tools, support policy delegation, and support API ordering`
    },
    {
      title: `Multi terminal support`,
      description: `Supports PC, mobile, and APP, and can be accessed through the Open API`
    },
    {
      title: `Data Assurance`,
      description: `Bank level user data encryption, dynamic data verification, multi-level branch identification control, ensuring transaction security, wallet multi-level encryption`
    },
    {
      title: `Allocation function`,
      description: `Supporting financial system and risk control system, supporting centralized wallet, supporting all mainstream digital assets, supporting backend audit and operation of CMS system, international language pack, rapid and stable system deployment and delivery`
    },
    {
      title: `Safe and efficient`,
      description: `The front end adopts firewall anti attack mechanism The back end adopts hidden separate deployment with white list access mechanism`
    },
    {
      title: `Rapid deployment`,
      description: `The system adopts a distributed architecture and is quickly and easily deployed to achieve rapid online testing of K-line, market, and deep data`
    }
  ];

  return <Box background={`#fff`} mt={NavBarHeight}>
    {/*<PSBanner*/}
    {/*  title={`CEX exchange Development`}*/}
    {/*  description={`Support multiple currency transaction modes, efficient, stable, safe and reliable.`}*/}
    {/*  icon={require(`../../assets/svg/business/business_1.svg`).default}*/}
    {/*/>*/}
    <Banner {...FadeUp}>
      <DecorateIcon style={{width: "56px", height: "56px", transform: "rotate(100deg)", left: "37%", top: "60px"}} />
      <DecorateIcon style={{width: "164px", height: "164px", transform: "rotate(100deg)", left: "60%", top: "unset", bottom: "24px"}} />
      <BannerContent>
        <BannerTitle>DXC Exchange Development</BannerTitle>
        <BannerSubtitle>Supporting multiple currency transaction modes, efficient, stable, safe, and reliable</BannerSubtitle>
        <BannerButton onClick={() => window.open("https://dxc.xsdx.xyz/", "_blank")}>TRIAL</BannerButton>
      </BannerContent>
    </Banner>
    <ContentStyle>
      <div {...FadeUp}>
        <Title>Product Function Introduction</Title>
        <FunctionStyle>
          {
            functions.map((item, index) => {
              return <div key={index}>
                <img src={item.icon} style={{width: "60px", height: "60px"}} alt=""/>
                <FunctionTitle>{item.title}</FunctionTitle>
                <div>{item.description}</div>
              </div>
            })
          }
        </FunctionStyle>
      </div>
      <CharacteristicsStyle mt={"140px"} {...FadeUp}>
        <DecorateIcon style={{width: "100px", height: "100px", transform: "rotate(-30deg)", left: "0", top: "-32px"}} />
        <DecorateIcon style={{width: "164px", height: "164px", transform: "rotate(100deg)", right: "50px", top: "30px"}} />
        <DecorateIcon style={{width: "310px", height: "310px", transform: "rotate(220deg)", right: "304px", bottom: "-100px"}} />

        <Title>function characteristics</Title>
        <CharacteristicsContent>
          {
            Characteristics.map((item, index) => {
              return <CharacteristicsGroup key={index}>
                <CharacteristicsTitle>{item.title}</CharacteristicsTitle>
                <div style={{color: "#fff"}}>{item.description}</div>
              </CharacteristicsGroup>
            })
          }
        </CharacteristicsContent>
      </CharacteristicsStyle>
      <AdvantagesContainer {...FadeUp}>
        <AdvantagesTitle>Product advantages</AdvantagesTitle>
        <AdvantagesContent>
          <AdvantagesDecorate src={textTag} style={{top: "75px", left: "40px", zIndex: -1}} />
          <AdvantagesDecorate src={textTag} style={{right: "35px", bottom: "55px", zIndex: -1}} />

          <AdvantagesGroup>
            <Flex alignItems={"center"} mb={"18px"}>
              <img src={arrowIcon} style={{width: "22px", height: "22px", marginRight: "10px"}} alt=""/>
              <AdvantagesGroupTitle active={true}>High performance matching engine</AdvantagesGroupTitle>
            </Flex>
            <AdvantagesDesc>
              <div>Asynchronous matching mechanism, 50000 orders per second; Memory level matching engines can rival financial trading systems; Engine clustering, getting away from stuck or hanging up from now on</div>
            </AdvantagesDesc>
          </AdvantagesGroup>
          <AdvantagesGroup>
            <Flex alignItems={"center"} mb={"18px"}>
              <img src={arrowIcon} style={{width: "22px", height: "22px", marginRight: "10px"}} alt=""/>
              <AdvantagesGroupTitle>System security guarantee</AdvantagesGroupTitle>
            </Flex>
            <AdvantagesDesc></AdvantagesDesc>
          </AdvantagesGroup>
          <AdvantagesGroup>
            <Flex alignItems={"center"} mb={"18px"}>
              <img src={arrowIcon} style={{width: "22px", height: "22px", marginRight: "10px"}} alt=""/>
              <AdvantagesGroupTitle>Independent Account System</AdvantagesGroupTitle>
            </Flex>
            <AdvantagesDesc></AdvantagesDesc>
          </AdvantagesGroup>
          <AdvantagesGroup>
            <Flex alignItems={"center"} mb={"18px"}>
              <img src={arrowIcon} style={{width: "22px", height: "22px", marginRight: "10px"}} alt=""/>
              <AdvantagesGroupTitle>Powerful management backend</AdvantagesGroupTitle>
            </Flex>
            <AdvantagesDesc></AdvantagesDesc>
          </AdvantagesGroup>
        </AdvantagesContent>
      </AdvantagesContainer>
    </ContentStyle>
  </Box>
}
