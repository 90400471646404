import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";
import bannerBg from "../../assets/images/nft/banner.png";
import bgIcon1 from "../../assets/images/trading/bgIcon1.png";
import Box from "../../components/Box";
import Flex from "../../components/Box/Flex";

export const BannerContent = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    height: 550px;
    margin: 0 auto 0;
    color: #fff;
    padding-bottom: 96px;
    background: 
            url(${bannerBg}) 625px bottom / 398px 422px no-repeat,
            url(${bgIcon1}) left 0px / 190px 190px no-repeat;
`;
export const BannerTitle = styled.h3`
    font-size: 80px;
    font-weight: 900;
    line-height: 80px;
    margin: 0 0 14px;
    padding-left: 28px;
    color: #00032E;
    padding-top: 12px;
`;
export const BannerSubtitle = styled.h5`
    font-size: 20px;
    font-weight: 500;
    color: #3D3D3D;
    padding-left: 28px;
`;
export const BannerButton = styled.button`
    width: 144px;
    height: 58px;
    border-radius: 4px;
    opacity: 1;
    background: #15A83C;
    border: none;
    box-shadow: 0px 8px 10px 0px rgba(66, 93, 73, 0.1);
    font-size: 28px;
    font-weight: 700;
    margin-top: 56px;
    margin-left: 28px;
    cursor: pointer;
`;

export const IntroBox = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: -40px auto 120px;
`;
export const IntroIconBox = styled(Flex)`
    height: 30px;
    align-items: flex-end;
`;
export const IntroTitle = styled.h3`
    font-size: 40px;
    font-weight: 900;
    color: #3D3D3D;
    margin-bottom: 78px;
`;
export const IntroContent = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
`;
export const IntroGroup = styled(Box)`
    min-height: 200px;
    padding: 18px 46px;
    border-radius: 12px;
    opacity: 1;
    background: #F6F6F6;
`;
export const IntroGroupTitle = styled.h5`
    font-size: 18px;
    font-weight: 700;
    margin: 12px 0 10px;
    color: #3D3D3D;
`;
export const IntroGroupContent = styled.div`
    font-size: 14px;
    color: #3D3D3D;
`;


export const SamplesBox = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 0 auto 70px;
`;
export const SamplesTitle = styled.h3`
    font-size: 40px;
    font-weight: 700;
    color: #3D3D3D;
    margin-bottom: 0;
`;
export const SamplesItem = styled(Box)<{active?: boolean}>`
    height: ${({active}) => active ? "500px" : "105px"};
    border-bottom: 1px solid #D8D8D8;
    padding-right: 24px;
    padding-top: 36px;
    padding-bottom: 36px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s all;
`;
export const SamplesItemHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
`;
export const ArrowBox = styled.div<{active?: boolean}>`
    transform: rotate(${({active}) => active? "180deg" : "0deg"});
    transition: 0.3s all;
`;
export const SamplesItemTitle = styled(Flex)`
    font-size: 22px;
    font-weight: 700;
`;
export const ColorText = styled.span`
    color: #23C84F;
    margin: 0 4px;
`;
export const SamplesItemContent = styled.div`
    font-size: 18px;
    font-weight: 400;
    margin: 6px 0 14px;
    color: #3D3D3D;
`;

