import Section from "../../components/Section";
import Box from "../../components/Box";
import CoreBusiness from "./CoreBusiness";
import OurDevelopment from "./OurDevelopment";
import ProjectCases from "./ProjectCases";
import ServiceProcess from "./ServiceProcess";
import ConsultNow from "../../views/ConsultNow";
import Banner from "./Banner";
import CoreBusinessSwiper from "./CoreBusinessSwiper";
import CoreBusiness2 from "./CoreBusiness2";
import React from "react";
import ProjectCases2 from "./ProjectCases2";
import ServiceProcess2 from "./ServiceProcess2";
import GaussianBackground from "../../views/GaussianBackground";
import BusinessData from "./BusinessData";
import InnoWeb from "./InnoWeb";
import Advantages from "./Advantages";
import Serviceable from "./Serviceable";
import Ecological from "./Ecological";
import PublicChains from "./PublicChains";
import ProjectCases3 from "./ProjectCases3";

const Home = () => {
  return <Box
    position={"relative"}
    // background={`#0A0B0E`}
  >
    {/*<GaussianBackground/>*/}
    <Banner/>
    <BusinessData />
    {/*<CoreBusinessSwiper/>*/}
    <CoreBusiness2/>
    <InnoWeb />
    <Advantages />
    <Serviceable />
    <Ecological />
    <PublicChains />
    <ProjectCases3 />
    {/*<CoreBusiness/>*/}
    {/*<OurDevelopment/>*/}
    {/*<ProjectCases2/>*/}
    {/*<ServiceProcess/>*/}
    {/*<ServiceProcess2/>*/}
    {/*<Box marginTop={`64px`}>*/}
    {/*  <ConsultNow/>*/}
    {/*</Box>*/}
  </Box>
}

export default Home
