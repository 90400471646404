import React, { useState } from 'react';
import styled from "styled-components";
import { Pagination, Autoplay, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import Flex from "../../components/Box/Flex";
import { BOX_CONTENT_WIDTH } from "../../styles";
import arrow_right from 'src/assets/svg/arrow_right.svg';
import RCarousel from "../../components/carousel/RCarousel";
import caseImg1 from 'src/assets/images/cases/1.png';
import caseImg2 from 'src/assets/images/cases/2.png';
import caseImg3 from 'src/assets/images/cases/3.png';
import caseImg4 from 'src/assets/images/cases/4.png';
import caseImg5 from 'src/assets/images/cases/5.png';
import caseImg6 from 'src/assets/images/cases/6.png';
import processBg from 'src/assets/images/cases/processBg.png';
import { Swiper as SwiperClass } from "swiper/types";
import { FadeUp } from "../../styles/aos";

const ProjectCases3Style = styled.div`
    position: relative;
    width: ${BOX_CONTENT_WIDTH};
    margin: 156px auto 72px;
`;

const Title = styled.h3`
    font-size: 60px;
    font-weight: 700;
    color: #3D3D3D;
    margin: 0 0 34px 0;
`;

const Tag = styled.div`
    position: relative;
    font-size: 30px;
    font-weight: 600;
    margin-right: 20px;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 100%;
        height: 4px;
        background: #60FE83;
        transform: translateY(-200%);
        z-index: -1;
    }
`;

const CasesGroup = styled.div<{active: boolean}>`
    font-weight: ${({active}) => active ? 700 : 400};
    &:not(:last-child){
        margin-bottom: 42px;
    }
`;

const CasesContainer = styled.div`
    position: absolute;
    right: 0;
    top: 104px;
    width: 766px;
    height: 496px;
    overflow: hidden;
    .pagination-bullet{
        display: block;
        width: 11px;
        height: 11px;
        opacity: 1;
        box-sizing: border-box;
        border: 2px solid #020714;
        border-radius: 50%;
        &:not(:last-child){
            margin-bottom: 8px;
        }
        &.pagination-bullet-active{
            background: #020714;
        }
    }
`;

const ProcessBg = styled.div`
    width: 1085px;
    height: 270px;
    background: url(${processBg});
    background-size: 100% 100%;
    margin-top: 156px;
`;

const Bullet = styled.div`
    
`;

export default function ProjectCases3() {
  const [active, setActive] = useState(0);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | undefined>();

  const Cases = [
    {
      text: "DEX Exchange",
      img: caseImg1
    },
    {
      text: "Decentralized lending",
      img: caseImg2
    },
    {
      text: "Cross chain bridge",
      img: caseImg3
    },
    {
      text: "Decentralized trading and mining",
      img: caseImg4
    },
    {
      text: "NTF Trading System",
      img: caseImg5
    },
    {
      text: "Blockchain Wallet",
      img: caseImg6
    }
  ];

  return (
    <ProjectCases3Style {...FadeUp}>
      <Title>Project Cases</Title>
      <div>
        {
          Cases.map((item, index) => {
            return <CasesGroup key={index} active={index === active} onClick={() => {
              if (controlledSwiper) {
                controlledSwiper.slideTo(index + 1)
              }
            }}>
              <Flex alignItems={"center"}>
                <Tag>0{index + 1}</Tag>
                <span>{item.text}</span>
              </Flex>
              <div style={{height: "24px"}}>
                {
                  index === active
                    ? <img src={arrow_right} style={{width: "24px", height: "24px", marginLeft: "52px"}} alt=""/>
                    : null
                }
              </div>
            </CasesGroup>
          })
        }
      </div>
      <CasesContainer>
        <Swiper
          modules={[Pagination, Autoplay, Controller]}
          slidesPerView={1}
          direction={"vertical"}
          height={496}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: false
          }}
          pagination={{
            el: ".swiper-pagination",
            bulletClass: "pagination-bullet",
            bulletActiveClass: "pagination-bullet-active",
            clickable: true
          }}
          onSlideChange={(swiper) => {
            console.log('slide change');
            console.log(swiper.realIndex)
            setActive(swiper.realIndex);
          }}
          onSwiper={(swiper) => setControlledSwiper(swiper)}
        >
          {
            Cases.map((item, index) => {
              return <SwiperSlide key={index}>
                <img src={item.img} key={index} style={{width: "766px"}} alt=""/>
              </SwiperSlide>
            })
          }
        </Swiper>
        <div className={"swiper-pagination"} style={{left: 0}}></div>
      </CasesContainer>
      <ProcessBg />
    </ProjectCases3Style>
  )
}
