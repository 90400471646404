import React from 'react';
import styled from "styled-components";
import { BOX_CONTENT_WIDTH } from "../../styles";

import InnoWeb1 from 'src/assets/images/InnoWeb1.png';
import InnoWeb2 from 'src/assets/images/InnoWeb2.png';
import InnoWeb3 from 'src/assets/images/InnoWeb3.png';
import { FadeUp } from "../../styles/aos";


const InnoWebStyle = styled.div`
    width: ${BOX_CONTENT_WIDTH};
    margin: 40px auto;

`;
const InnoWebTitle = styled.h3`
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 160px;
`;
const InnoWebContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
`;
export default function InnoWeb() {

  return (
    <InnoWebStyle  {...FadeUp}>
      <InnoWebTitle>Why InnoWeb3?</InnoWebTitle>
      <InnoWebContainer>
        <img src={InnoWeb1} style={{width: "500px"}} alt=""/>
        <img src={InnoWeb2} style={{width: "540px"}} alt=""/>
        <div style={{marginTop: "70px", gridColumnStart: "span 2", textAlign: "center"}}>
          <img src={InnoWeb3} style={{width: "640px"}} alt=""/>
        </div>
      </InnoWebContainer>
    </InnoWebStyle>
  )
}
