import {MediaQueries} from "./types";
import {DefaultTheme} from "styled-components";

export const MEDIA_WIDTHS: { [key: string]: number } = {
  sm: 720,
  md: 960,
  xl: 1280,
}

const mediaQueries: MediaQueries = {
  sm: `@media screen and (max-width: ${MEDIA_WIDTHS.sm}px)`,
  md: `@media screen and (max-width: ${MEDIA_WIDTHS.md}px)`,
  xl: `@media screen and (max-width: ${MEDIA_WIDTHS.xl}px)`,
};

const theme: DefaultTheme = {
  mediaQueries
}

export default theme
